import { useCallback, useMemo } from 'react';
import { childTestID } from '../../util/test-id';
import { Dispatch, Group, Option, ReducerState } from './types';
import { mapIt } from './util';
import { GroupLabelView, GroupView, OptionView, OptionViewProps } from './views';

export const optionId = (id: string, { group, value }: Option): string => childTestID(id, `option-${group}-${value}`);
const optionTestId = (id: string | undefined, list: string, { group, label }: Option) =>
  childTestID(id, `${list}-option-${group}-${label.split(' ').join('-')}`.toLowerCase());

const groupId = (id: string, list: string, group: Group) => childTestID(id, `${list}-group-${group.name}`);
const groupTestId = (id: string | undefined, list: string, { name }: Group) => childTestID(id, `${list}-group-${name}`);

interface RenderOptionsProps {
  id: string;
  testID?: string;
}

type OnOptionClick = NonNullable<OptionViewProps['onClick']>;

const useRenderOptions = (
  options: Option[],
  active: number,
  list: 'selected' | 'suggested',
  onClick: OnOptionClick,
  { id, testID }: RenderOptionsProps,
) => {
  const getLabel = useCallback(
    (option: Option): string => {
      const sameGroupOptions = options.filter((o) => o.group === option.group);
      const index = sameGroupOptions.findIndex((o) => o.value === option.value) + 1;
      return `${option.label} (${index} of ${sameGroupOptions.length})`;
    },
    [options],
  );

  return useMemo(() => {
    return options.map((option, index) => (
      <OptionView
        key={`${list}-${optionId(id, option)}`}
        id={optionId(id, option)}
        testID={optionTestId(testID, list, option)}
        selected={index === active}
        data-list={list}
        data-group={option.group}
        data-value={option.value}
        onClick={onClick}
        aria-label={getLabel(option)}
      >
        {option.label}
      </OptionView>
    ));
  }, [active, id, list, onClick, options, testID, getLabel]);
};

const useGroupedOptions = (options: Option[], rendered: JSX.Element[]) => {
  return useMemo(() => {
    const result: JSX.Element[][] = [];
    let current: JSX.Element[];
    options.forEach((option, index, list) => {
      if (!index || list[index - 1].group !== option.group) {
        current = [];
        result.push(current);
      }
      current.push(rendered[index]);
    });
    return result;
  }, [options, rendered]);
};

const useRenderGroups = (
  grouped: JSX.Element[][],
  groups: Record<string, Group>,
  list: 'selected' | 'suggested',
  { id, testID }: RenderOptionsProps,
) => {
  return useMemo(() => {
    return grouped
      .map((options): [Group, JSX.Element[]] => [groups[options[0].props['data-group']], options])
      .map(([group, options]) => (
        <GroupView
          key={group.name}
          variant={list}
          aria-labelledby={groupId(id, list, group)}
          testID={groupTestId(testID, list, group)}
        >
          {list === 'suggested' && <GroupLabelView id={groupId(id, list, group)}>{group.label}</GroupLabelView>}
          {list === 'selected' && (
            <GroupLabelView id={groupId(id, list, group)}>{group.shortLabel || group.label}:</GroupLabelView>
          )}
          {options}
        </GroupView>
      ));
  }, [grouped, groups, id, list, testID]);
};

export interface RenderContentsProps extends RenderOptionsProps {
  groups: Group[];
}

export const useActiveOptionId = (
  { selected, activeSelect, suggested, activeSuggest }: ReducerState,
  { id }: RenderContentsProps,
): string | undefined => {
  const activeOption = selected[activeSelect] || suggested[activeSuggest];
  const activeOptionId = activeOption && optionId(id, activeOption);
  return activeOptionId;
};

export const useRenderContents = (
  { selected, activeSelect, suggested, activeSuggest }: ReducerState,
  props: RenderContentsProps,
  dispatch: Dispatch,
): [JSX.Element[], JSX.Element[]] => {
  const { groups } = props;
  const onOptionClick: OnOptionClick = useCallback(
    (event) => {
      type DataSet = { list: 'selected' | 'suggested'; group: string; value: string };
      const { list, group, value } = event.currentTarget.dataset as DataSet;
      dispatch(['TOGGLE_OPTION', { list, group, value }]);
    },
    [dispatch],
  );

  const groupMap = useMemo(() => mapIt(groups, (g) => g.name), [groups]);
  const selectedOptions = useRenderOptions(selected, activeSelect, 'selected', onOptionClick, props);
  const suggestedOptions = useRenderOptions(suggested, activeSuggest, 'suggested', onOptionClick, props);

  const groupedSelected = useGroupedOptions(selected, selectedOptions);
  const groupedSuggested = useGroupedOptions(suggested, suggestedOptions);

  const selectedGroups = useRenderGroups(groupedSelected, groupMap, 'selected', props);
  const suggestedGroups = useRenderGroups(groupedSuggested, groupMap, 'suggested', props);

  return [selectedGroups, suggestedGroups];
};
