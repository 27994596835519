import { Dayjs } from 'dayjs';
import { utcDate } from '../../components/Calendar';
import { Option } from '../../components/FilterComboBox';
import { Article } from '../../components/ReportsFilterComboBox';
import { parseQuery } from '../../sessions';
import { ReportDatePickerTab, TabId } from './ReportsProvider';

interface CentreInfo {
  id: string;
  name: string;
}

const basicSort = (a: Option, b: Option) => a.label.localeCompare(b.label);

export const readCentreOptions = (centres: CentreInfo[]): Option[] => {
  type OMap = Record<string, Option>;

  const centreMap = centres.reduce<OMap>((acc, centre) => {
    const value = centre.id;
    const label = centre.id;
    acc[value] = acc[value] || { group: 'centre', value, label };
    return acc;
  }, {});

  const centre: Option[] = Object.values(centreMap).sort(basicSort);

  return [...centre];
};

export const buildArticleQuery = (params: Article | null): string => {
  if (!params || (!params.id && !params.name)) {
    return '';
  }
  const splitted = params.name.split(' ');
  if (splitted.length > 1) {
    const joined = splitted.join('_');
    return `${encodeURIComponent(params.id)}*${encodeURIComponent(joined)}`;
  }

  return `${encodeURIComponent(params.id)}*${encodeURIComponent(params.name)}`;
};

export const buildCentreQuery = (selected: Option[]): string | null => {
  if (!selected.length) return null;

  const grouped = selected.reduce<Record<string, Option[]>>((acc, item) => {
    (acc[item.group] = acc[item.group] || []).push(item);
    return acc;
  }, {});

  const result = [];
  const keys = ['centre'];
  for (const key in grouped) {
    if (keys.indexOf(key) > -1) result.push([key, ...grouped[key].map((o) => o.value)]);
  }

  return result.map((e) => e.join('.')).join('*');
};

export const getSearch = (): Record<string, string> => {
  return window.location.search
    .substr(1)
    .split('&')
    .map((x) => x.split('='))
    .filter(([k, v]) => k && v)
    .reduce<Record<string, string>>((acc, [k, v]) => ({ ...acc, [k]: decodeURIComponent(v) }), {});
};

export const parseArticleSearchQuery = (query: string | null, options: Article[]): Article | null => {
  if (!query) return null;

  const splitted = query.split('*');

  if (splitted.length <= 1) return null;

  const selectedItem = {
    id: splitted[0],
    name: splitted[1].split('_').length > 1 ? splitted[1].split('_').join(' ') : splitted[1],
  };

  if (options.find((o) => o.id === selectedItem.id && o.name === selectedItem.name)) {
    return selectedItem;
  }

  return null;
};

export const parseCentresSearchQuery = (query: string | null, options: Option[], decodeURI?: boolean): Option[] => {
  if (!query) return [];

  return parseQuery(query)
    .map(([group, value]) => {
      return options.find((o) => o.group === group && o.value === (decodeURI ? decodeURIComponent(value) : value));
    })
    .filter<Option>((o): o is Option => !!o);
};

const getDateinRange = (start: Dayjs, end: Dayjs, date: Dayjs): Dayjs => {
  if (start.valueOf() > date.valueOf()) return start;
  if (date.valueOf() > end.valueOf()) return end;
  return date;
};

const getMaxFrom = (from: Dayjs, to: Dayjs, max: number): Dayjs => {
  if (max <= 0) return from;

  const utcTo = utcDate(to);
  const maxFrom = utcTo.set('date', utcTo.get('date') - (max - 1));

  if (maxFrom.valueOf() > from.valueOf()) return maxFrom;
  return from;
};

export const parseReportsDateQuery = (
  query: string | null,
  reportId: string | null,
  startString: string,
  maxDays: number,
): ReportDatePickerTab | null => {
  if (!query || !reportId) return null;

  const tab: [string, string][] = parseQuery(query)
    .map(([group, value]): [string, string] => [group, value])
    .filter(([group]) => group === 'id');

  const result: [string, Dayjs][] = parseQuery(query)
    .map(([group, value]): [string, Dayjs] => [group, utcDate(value)])
    .filter(([group, value]) => (group === 'from' || group === 'to') && !isNaN(value.valueOf()))
    .sort((a, b) => a[1].valueOf() - b[1].valueOf());

  const startDate = utcDate(startString);
  const today = utcDate();

  if (result.length === 0) return null;
  if (result.length === 1)
    return {
      from: getDateinRange(startDate, today, utcDate(result[0][1])),
      to: null,
      id: tab[0][1],
      reportId: reportId as Lowercase<TabId>,
    };

  const to = getDateinRange(startDate, today, utcDate(result[1][1]));
  const from = getMaxFrom(getDateinRange(startDate, today, utcDate(result[0][1])), to, maxDays);

  return {
    from,
    to,
    id: tab[0][1],
    reportId: reportId as Lowercase<TabId>,
  };
};

export const getSearchParams = (search: string, tabId: TabId): URLSearchParams => {
  let params = new URLSearchParams(search);

  const reportId = params.get('report');

  if (!reportId || reportId !== tabId.toLowerCase()) {
    params = new URLSearchParams();
    params.set('report', tabId.toLowerCase());
  }
  return params;
};
