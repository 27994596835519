import { FC, Fragment, MouseEvent, useCallback, useMemo, useState } from 'react';
import {
  ArticleCE,
  ArticleCI,
  BusinessStreamName,
  Download,
  Session,
  SessionCE,
  SessionCI,
  SupervisorSessionsCE,
  SupervisorSessionsCI,
} from '../api';
import { Button, SpanButton } from '../components/Button';
import { DropdownMenuContainer, DropdownMenuList } from '../components/DropDown';
import { ArrowDownward } from '../components/Icon';
import { ConnectedPagination, useCurrentPage } from '../components/Pagination';
import { useSignature } from '../singnature';
import { useSessionList, useSessionItem } from '../sessions';
import { useNotification, NotificationWaitingMessage } from '../notifications';
import {
  AvailableFromMessage,
  GetHelpMessage,
  SessionRowCE,
  SessionRowCI,
  SessionsTable,
  PreparingFileMessage,
  ReplacementFileWarning,
  NewFileWarning,
  NoSessionsMessageView,
  NoArticlesMessageView,
} from './views';
import { useCentres } from '../centres';
import { isBusinessStreamCI } from '../centres/businessStreamCheck';
import { useBootState, useIsSupervisor } from '../boot';
import { Dayjs } from 'dayjs';
import { utcDate, toDateString } from '../components/Calendar';
interface FileInfo {
  product: string;
  specialArrangement: boolean;
  date: Dayjs;
  sitting: string;
  articleNo: string;
}

function getFileInfo(session: Session, businessStream: BusinessStreamName): FileInfo {
  let product = '';
  let specialArrangement = false;
  let sitting = '';

  if (isBusinessStreamCI(businessStream)) {
    const sessionCI = session as SessionCI;
    const { qualificationShortName, assessmentId, componentId } = sessionCI.article;
    product = `${qualificationShortName} - ${assessmentId} - ${componentId}`;
    sitting = sessionCI.sitting || '';
  } else {
    const sessionCE = session as SessionCE;

    product = sessionCE.article.product;
    specialArrangement = sessionCE.article.specialArrangement;
    sitting = sessionCE.sitting;
  }

  const fileInfo: FileInfo = {
    product,
    specialArrangement,
    sitting,
    articleNo: session.id,
    date: session.date,
  };

  return fileInfo;
}

const DownloadAction: FC<{ id: string }> = ({ id }) => {
  const item = useSessionItem(id);
  const { businessStream } = useCentres();
  if (!item) return null;

  const {
    download,
    available: { from },
    isNew,
  } = item;

  const fileInfo = getFileInfo(item, businessStream);

  const isReplacementFile = useMemo(() => download && download.length && download[0].isReplacement, [download]);
  const isNewFile = useMemo(() => download && download.length && !download[0].isReplacement && isNew, [
    download,
    isNew,
  ]);

  const signing = !!download && useDownloadPending(download);

  if (!download) return <AvailableFromMessage date={from} />;
  if (!download.length) return <GetHelpMessage testID={`session-${id}`} />;
  if (signing) return <PreparingFileMessage />;

  return (
    <Fragment>
      {isReplacementFile && <ReplacementFileWarning testID={`session-${id}`} />}
      {isNewFile && <NewFileWarning testID={`session-${id}`} />}
      <DownloadDropdown id={id} list={download} fileInfo={fileInfo} />
    </Fragment>
  );
};

const useDownloadPending = (list: Download[]): boolean => {
  const {
    state: { requests },
  } = useSignature();
  return list.some((item) => !!requests[item.filename]);
};

export const DownloadDropdown: FC<{ id: string; list: Download[]; fileInfo: FileInfo }> = ({ id, list, fileInfo }) => {
  const buttonId = `${id}-trigger`;
  const menuId = `${id}-menu`;
  const { businessStream } = useCentres();
  const session = useSessionItem(id);
  const boot = useBootState();
  const assignedSessions = useMemo(() => (!boot.loading && boot.user?.globalListening?.sessions) || [], [boot]);
  const { selectedCentre } = useCentres();

  const activeDownload = useMemo(
    () =>
      !!selectedCentre &&
      selectedCentre.roles.some(
        (r) =>
          r === 'EXAMS_OFFICER' ||
          r === 'DELEGATED_ADMIN' ||
          (r === 'SUPERVISOR' &&
            session &&
            assignedSessions.some((s) => {
              const isSameCentreId = s.legacyCentreNumber === selectedCentre.id;
              const isSameArticleNumber = s.articleNumber === session.article.id;

              let productResult: boolean;
              if (isBusinessStreamCI(businessStream)) {
                const _s = s as SupervisorSessionsCI;
                const isSameExamDate = _s.examDate === toDateString(utcDate(session.date));
                productResult = isSameExamDate;
              } else {
                const _s = s as SupervisorSessionsCE;
                const isSameExamDate = _s.keyAssessmentDate === toDateString(utcDate(session.date));
                const isSameSitting = _s.sitting === (session as SessionCE).sitting;
                productResult = isSameExamDate && isSameSitting;
              }

              return isSameCentreId && isSameArticleNumber && productResult;
            })),
      ),
    [assignedSessions, selectedCentre, session, businessStream],
  );

  const { download } = useSignature();
  const { dispatch: dispatchNotification, messages, assignMessage } = useNotification();
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => setOpen(false), []);
  const onOpen = useCallback(() => setOpen(true), []);
  const onClick = useCallback(() => setOpen((value) => !value), []);

  const onLinkClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setOpen(false);
      const { filename, format, isZip } = event.currentTarget.dataset;
      if (filename) {
        download(filename, isZip === 'true');

        assignMessage(
          <NotificationWaitingMessage
            product={fileInfo.product}
            sa={fileInfo.specialArrangement}
            date={fileInfo.date}
            sitting={fileInfo.sitting}
            format={format}
          />,
          'FILE_BEING_PREPARED',
        );

        dispatchNotification([
          'ADD',
          {
            articleNo: fileInfo.articleNo,
            title: `Preparing file for ${fileInfo.product}`,
            body: messages.FILE_BEING_PREPARED,
          },
          'WAITING',
        ]);
      }
    },
    [download, dispatchNotification, assignMessage, messages, fileInfo],
  );

  return (
    <DropdownMenuContainer testID={`session-${id}-download-dropdown`} open={open} onClose={onClose} onOpen={onOpen}>
      <Button
        disabled={!activeDownload}
        id={buttonId}
        variant="text"
        size="small"
        startIcon={<ArrowDownward />}
        aria-haspopup="true"
        aria-controls={menuId}
        onClick={onClick}
        active={open}
        aria-expanded={open}
      >
        Download
      </Button>
      <DropdownMenuList
        visible={open}
        id={menuId}
        aria-labelledby={buttonId}
        items={list.map(({ format, filename, isZip }) => (
          <SpanButton
            size="small"
            variant="text"
            key={format}
            data-filename={filename}
            data-format={format}
            data-is-zip={isZip}
            onClick={onLinkClick}
          >
            .{format.toLocaleLowerCase()}
          </SpanButton>
        ))}
      />
    </DropdownMenuContainer>
  );
};

const CentreSessionRow: FC<{ item: Session }> = ({ item }) => {
  const { businessStream } = useCentres();

  if (isBusinessStreamCI(businessStream)) {
    const article = item.article as ArticleCI<'processed'>;
    return (
      <SessionRowCI
        testID={`session-${item.id}`}
        componentId={article.componentId}
        assessmentId={article.assessmentId}
        assessmentShortName={article.assessmentShortName}
        componentShortName={article.componentShortName}
        qualificationShortName={article.qualificationShortName}
        examDate={item.date}
        sitting={(item as SessionCI<'processed'>).sitting}
        actions={<DownloadAction id={item.id} />}
      />
    );
  }

  const article = item.article as ArticleCE<'processed'>;
  return (
    <SessionRowCE
      testID={`session-${item.id}`}
      product={article.product}
      date={item.date}
      sitting={(item as SessionCE<'processed'>).sitting}
      specialArrangements={article.specialArrangement}
      actions={<DownloadAction id={item.id} />}
    />
  );
};

export const CentreSessionsTable: FC = () => {
  const sessionList = useSessionList();
  const { businessStream } = useCentres();
  const { totalPages, list } = sessionList;

  const itemArr = list?.map((id) => useSessionItem(id));
  const rows = (itemArr as Session[])?.map((s: Session) => s && <CentreSessionRow item={s} key={s.id} />);

  return (
    <SessionsTable
      rows={rows}
      testID="sessions-list"
      aria-describedby="upcoming-sessions-title"
      pagination={totalPages > 1 && <ConnectedPagination aria-label="Session list navigation" total={totalPages} />}
      businessStream={businessStream}
    />
  );
};

export const NoSessionsMessage: FC = () => {
  const sessionList = useSessionList();
  const currentPage = useCurrentPage();
  const { list, totalPages } = sessionList;

  const { selectedCentre } = useCentres();
  const isSupervisor = useIsSupervisor(selectedCentre?.id || null);

  if (list && list.length) return null;
  else if (currentPage > totalPages) return <NoArticlesMessageView testID="no-articles" />;
  return <NoSessionsMessageView isSupervisor={isSupervisor} testID="no-sessions" />;
};
