import clsx from 'clsx';
import { forwardRef, ForwardRefRenderFunction, memo, SVGProps } from 'react';
import { root } from './Icon.module.scss';

type FC<T = SVGSVGElement, P = IconProps> = ForwardRefRenderFunction<T, P>;

export interface IconProps extends SVGProps<SVGSVGElement> {
  testID?: string;
  titleAccess?: string;
}

const BaseIcon: FC = ({ testID, titleAccess, className, children, ...rest }, ref) => (
  <svg
    className={clsx(root, className)}
    data-qa-id={testID}
    aria-hidden={titleAccess ? undefined : true}
    role={titleAccess ? 'img' : undefined}
    focusable="false"
    viewBox="0 0 18 18"
    ref={ref}
    {...rest}
  >
    {children}
    {titleAccess ? <title>{titleAccess}</title> : null}
  </svg>
);

export const Icon = memo(forwardRef(BaseIcon));

const AddIcon: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M10.2 7.8V4.2C10.2 3.54 9.66 3 9 3c-.66 0-1.2.54-1.2 1.2v3.6H4.2C3.54 7.8 3 8.34 3 9c0 .66.54 1.2 1.2 1.2h3.6v3.6c0 .66.54 1.2 1.2 1.2.66 0 1.2-.54 1.2-1.2v-3.6h3.6c.66 0 1.2-.54 1.2-1.2 0-.66-.54-1.2-1.2-1.2h-3.6z" />
    {props.children}
  </Icon>
);

export const Add = memo(forwardRef(AddIcon));

const ArrowDownwardIcon: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path
      d="M5.557 9.3H6.75v3.75c.001.414.336.749.75.75h3c.414-.001.749-.336.75-.75V9.3h1.193c.304 0 .577-.183.694-.464.116-.28.053-.603-.161-.819L9.533 4.576c-.14-.14-.33-.22-.529-.22-.198 0-.388.08-.528.22L5.033 8.017c-.216.214-.281.536-.167.817.115.28.388.465.691.466z"
      transform="rotate(180 9 9)"
    />
    {props.children}
  </Icon>
);

export const ArrowDownward = memo(forwardRef(ArrowDownwardIcon));

const ArrowUpwardIcon: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path
      d="M.747-4.944h1.192v-3.75a.752.752 0 0 1 .75-.75h3a.752.752 0 0 1 .75.75v3.75h1.193a.752.752 0 0 1 .694.464.752.752 0 0 1-.161.819L4.722-.219A.746.746 0 0 1 4.194 0a.746.746 0 0 1-.529-.219L.222-3.661a.75.75 0 0 1-.166-.817.75.75 0 0 1 .691-.466z"
      transform="rotate(180 6.595 2.1)"
    />
    {props.children}
  </Icon>
);

export const ArrowUpward = memo(forwardRef(ArrowUpwardIcon));

const CloseIcon: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M13.213 13.213c-.382.383-1 .383-1.381 0L9 10.382l-2.832 2.831c-.382.383-1 .383-1.381 0-.383-.382-.383-1 0-1.381L7.618 9 4.787 6.168c-.383-.382-.383-1 0-1.381.382-.383 1-.383 1.381 0L9 7.618l2.832-2.831c.382-.383 1-.383 1.381 0 .383.382.383 1 0 1.381L10.382 9l2.831 2.832c.373.372.373 1 0 1.381z" />{' '}
    {props.children}
  </Icon>
);

export const Close = memo(forwardRef(CloseIcon));

const DoneIcon: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M14.57 4.842l.1.105c.44.458.44 1.196 0 1.654l-6.278 6.556c-.438.457-1.147.457-1.586 0L3.329 9.533c-.439-.457-.439-1.196 0-1.654l.101-.105c.44-.458 1.148-.458 1.587 0l2.588 2.697 5.378-5.618c.428-.469 1.148-.469 1.587-.011z" />
    {props.children}
  </Icon>
);

export const Done = memo(forwardRef(DoneIcon));

const LeftArrowPath: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M10.733 4.262c-.355-.35-.929-.35-1.284 0L5.267 8.369c-.356.35-.356.913 0 1.262l4.182 4.107c.355.35.93.35 1.284 0 .356-.349.356-.912 0-1.261L7.198 8.996l3.535-3.473c.356-.349.347-.921 0-1.261z" />
    {props.children}
  </Icon>
);

export const LeftArrow = memo(forwardRef(LeftArrowPath));

const RightArrowPath: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M7.267 4.262c.355-.35.929-.35 1.284 0l4.182 4.107c.356.35.356.913 0 1.262l-4.182 4.107c-.355.35-.93.35-1.284 0-.356-.349-.356-.912 0-1.261l3.535-3.481-3.535-3.473c-.356-.349-.347-.921 0-1.261z" />
    {props.children}
  </Icon>
);

export const RightArrow = memo(forwardRef(RightArrowPath));

const ListPath: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M9 5.85h4.2c.578 0 1.05-.472 1.05-1.05 0-.577-.473-1.05-1.05-1.05H9c-.578 0-1.05.473-1.05 1.05 0 .578.472 1.05 1.05 1.05zm0 4.2h4.2c.578 0 1.05-.473 1.05-1.05 0-.578-.473-1.05-1.05-1.05H9c-.578 0-1.05.472-1.05 1.05 0 .578.472 1.05 1.05 1.05zm0 4.2h4.2c.578 0 1.05-.473 1.05-1.05 0-.577-.473-1.05-1.05-1.05H9c-.578 0-1.05.473-1.05 1.05 0 .578.472 1.05 1.05 1.05zM3.75 3.75h2.1v2.1h-2.1v-2.1zm0 4.2h2.1v2.1h-2.1v-2.1zm0 4.2h2.1v2.1h-2.1v-2.1z" />
    {props.children}
  </Icon>
);

export const List = memo(forwardRef(ListPath));

const AlertPath: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M9 5.5c.385 0 .7.315.7.7V9c0 .385-.315.7-.7.7-.385 0-.7-.315-.7-.7V6.2c0-.385.315-.7.7-.7zM8.993 2C5.129 2 2 5.136 2 9s3.129 7 6.993 7C12.864 16 16 12.864 16 9s-3.136-7-7.007-7zM9 14.6c-3.094 0-5.6-2.506-5.6-5.6S5.906 3.4 9 3.4s5.6 2.506 5.6 5.6-2.506 5.6-5.6 5.6zm.7-2.1H8.3v-1.4h1.4v1.4z" />
    {props.children}
  </Icon>
);

export const Alert = memo(forwardRef(AlertPath));

const MorePath: FC = (props, ref) => (
  <Icon {...props} viewBox="0 0 24 24" ref={ref}>
    <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    {props.children}
  </Icon>
);

export const More = memo(forwardRef(MorePath));

const DeletePath: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M5.25 13.5c0 .825.675 1.5 1.5 1.5h4.5c.825 0 1.5-.675 1.5-1.5V6h-7.5v7.5zm7.5-9.75h-1.5l-.533-.533c-.135-.135-.33-.217-.524-.217H7.808c-.196 0-.39.083-.526.217l-.532.533h-1.5c-.412 0-.75.338-.75.75s.338.75.75.75h7.5c.412 0 .75-.338.75-.75s-.338-.75-.75-.75z" />
    {props.children}
  </Icon>
);

export const Delete = memo(forwardRef(DeletePath));

const ReplacePath: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M4.757 13.234l-1.028 1.029c-.12.125-.306.164-.466.098-.161-.067-.265-.224-.263-.398v-3.677c0-.114.044-.223.124-.304.08-.08.19-.125.304-.124h3.678c.173 0 .33.102.397.262.068.159.033.343-.088.467l-1.449 1.447c1.045 1.037 2.547 1.467 3.983 1.14 1.435-.326 2.604-1.364 3.097-2.751.118-.34.44-.569.8-.566.279.001.54.138.7.366.16.228.2.52.106.782-.194.552-.468 1.072-.815 1.544 0 0-.377.472-.59.685-.213.213-.695.6-.695.6-2.387 1.758-5.702 1.503-7.792-.6h-.003zm6.137-5.091c-.173.001-.33-.102-.397-.261-.067-.16-.032-.344.089-.467l1.448-1.449c-1.045-1.037-2.548-1.467-3.983-1.14-1.435.327-2.604 1.365-3.097 2.752-.117.34-.44.568-.8.565-.282 0-.546-.14-.706-.373-.16-.233-.195-.53-.094-.793.69-1.937 2.325-3.384 4.331-3.833 2.007-.448 4.103.164 5.552 1.622l1.035-1.029c.12-.124.305-.163.466-.096.16.066.264.223.262.396v3.677c.001.114-.044.223-.124.304-.08.08-.19.125-.304.124l-3.678.001z" />
    {props.children}
  </Icon>
);

export const Replace = memo(forwardRef(ReplacePath));

const SearchPath: FC = (props, ref) => (
  <Icon {...props} viewBox="0 0 24 24" ref={ref}>
    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
    {props.children}
  </Icon>
);

export const Search = memo(forwardRef(SearchPath));

const CheckBoxBlankPath: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M13.5 14.25h-9c-.412 0-.75-.338-.75-.75v-9c0-.412.338-.75.75-.75h9c.412 0 .75.338.75.75v9c0 .412-.338.75-.75.75zm.75-12H3.75c-.825 0-1.5.675-1.5 1.5v10.5c0 .825.675 1.5 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5V3.75c0-.825-.675-1.5-1.5-1.5z" />
    {props.children}
  </Icon>
);

export const CheckBoxBlank = memo(forwardRef(CheckBoxBlankPath));

const CheckBoxCheckedPath: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M14.25 2.25H3.75c-.825 0-1.5.675-1.5 1.5v10.5c0 .825.675 1.5 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5V3.75c0-.825-.675-1.5-1.5-1.5zm-6.217 9.967c-.293.293-.765.293-1.058 0L4.283 9.525c-.293-.293-.293-.765 0-1.058.292-.292.764-.292 1.057 0l2.16 2.16 5.16-5.16c.293-.292.765-.292 1.057 0 .293.293.293.766 0 1.058l-5.684 5.692z" />
    {props.children}
  </Icon>
);

export const CheckBoxChecked = memo(forwardRef(CheckBoxCheckedPath));

const CalendarPath: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M14.444 2.596h-.777v-.8c0-.44-.348-.796-.778-.796-.43 0-.778.356-.778.796v.8H5.89v-.8c0-.44-.349-.796-.778-.796-.43 0-.778.356-.778.796v.8h-.777c-.413.001-.809.17-1.1.471-.29.3-.452.707-.449 1.13L2 15.4c-.001.423.162.83.454 1.13.291.3.688.469 1.102.47h10.888c.861-.005 1.556-.72 1.556-1.6V4.196c0-.88-.695-1.595-1.556-1.6zM14.144 16H3.856c-.474-.003-.857-.368-.857-.818V7h12v8.182c0 .45-.383.815-.857.818zM4.8 8h2.4c.443.003.8.36.8.8v2.4c0 .44-.357.797-.8.8H4.8c-.443-.003-.8-.36-.8-.8V8.8c0-.44.357-.797.8-.8z" />
    {props.children}
  </Icon>
);

export const Calendar = memo(forwardRef(CalendarPath));

const DropDownPath: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M5.462 8.344l2.783 2.9c.2.21.473.327.757.327.285 0 .557-.117.758-.327l2.783-2.9c.308-.322.399-.805.231-1.224-.167-.419-.56-.692-.996-.691H6.211c-.435.002-.825.277-.989.696-.164.42-.07.9.24 1.219z" />
    {props.children}
  </Icon>
);

export const DropDown = memo(forwardRef(DropDownPath));

const TriangleDownPath: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M5.462 8.344l2.783 2.9c.2.21.473.327.757.327.285 0 .557-.117.758-.327l2.783-2.9c.308-.322.399-.805.231-1.224-.167-.419-.56-.692-.996-.691H6.211c-.435.002-.825.277-.989.696-.164.42-.07.9.24 1.219z" />
    {props.children}
  </Icon>
);

export const SortArrowDown = memo(forwardRef(TriangleDownPath));

const TriangleUpPath: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path
      d="M5.462 8.344l2.783 2.9c.2.21.473.327.757.327.285 0 .557-.117.758-.327l2.783-2.9c.308-.322.399-.805.231-1.224-.167-.419-.56-.692-.996-.691H6.211c-.435.002-.825.277-.989.696-.164.42-.07.9.24 1.219z"
      transform="rotate(180 9 9)"
    />
    {props.children}
  </Icon>
);

export const SortArrowUp = memo(forwardRef(TriangleUpPath));

const HourGlassPath: FC = (props, ref) => (
  <Icon {...props} ref={ref}>
    <path d="M6.5 3c-.69.002-1.248.538-1.25 1.2v1.902c0 .32.132.626.367.852L7.75 9l-2.133 2.052c-.234.227-.365.533-.367.852V13.8c.002.662.56 1.198 1.25 1.2h5c.69-.002 1.248-.538 1.25-1.2v-1.897c0-.318-.133-.622-.367-.846L10.25 9l2.132-2.04c.235-.226.367-.533.367-.853V4.2c-.002-.662-.56-1.197-1.249-1.2h-5zm5 8.699c0 1.83-5 1.83-5 0l2.5-2.4 2.5 2.4zm-2.5-3L6.5 6.3c0-1.83 5-1.83 5 0L9 8.7z" />
    {props.children}
  </Icon>
);

export const HourGlass = memo(forwardRef(HourGlassPath));

const Forward30Path: FC = (props, ref) => (
  <Icon {...props} ref={ref} viewBox="2 2 20 20">
    <path d="M18 13c0 3.31-2.69 6-6 6s-6-2.69-6-6 2.69-6 6-6v4l5-5-5-5v4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8h-2z" />
    <path d="M9.56 13.49h.45c.21 0 .37-.05.48-.16s.16-.25.16-.43c0-.08-.01-.15-.04-.22s-.06-.12-.11-.17-.11-.09-.18-.11-.16-.04-.25-.04c-.08 0-.15.01-.22.03s-.13.05-.18.1-.09.09-.12.15-.05.13-.05.2h-.85c0-.18.04-.34.11-.48s.17-.27.3-.37.27-.18.44-.23.35-.08.54-.08c.21 0 .41.03.59.08s.33.13.46.23.23.23.3.38.11.33.11.53c0 .09-.01.18-.04.27s-.07.17-.13.25-.12.15-.2.22-.17.12-.28.17c.24.09.42.21.54.39s.18.38.18.61c0 .2-.04.38-.12.53s-.18.29-.32.39-.29.19-.48.24-.38.08-.6.08c-.18 0-.36-.02-.53-.07s-.33-.12-.46-.23-.25-.23-.33-.38-.12-.34-.12-.55h.85c0 .08.02.15.05.22s.07.12.13.17.12.09.2.11.16.04.25.04c.1 0 .19-.01.27-.04s.15-.07.2-.12.1-.11.13-.18.04-.15.04-.24c0-.11-.02-.21-.05-.29s-.08-.15-.14-.2-.13-.09-.22-.11-.18-.04-.29-.04h-.47v-.65zM15.3 14.24c0 .32-.03.6-.1.82s-.17.42-.29.57-.28.26-.45.33-.37.1-.59.1-.41-.03-.59-.1-.33-.18-.46-.33-.23-.34-.3-.57-.11-.5-.11-.82v-.74c0-.32.03-.6.1-.82s.17-.42.29-.57.28-.26.45-.33.37-.1.59-.1.41.03.59.1.33.18.46.33.23.34.3.57.11.5.11.82v.74zm-.85-.86c0-.19-.01-.35-.04-.48s-.07-.23-.12-.31-.11-.14-.19-.17-.16-.05-.25-.05-.18.02-.25.05-.14.09-.19.17-.09.18-.12.31-.04.29-.04.48v.97c0 .19.01.35.04.48s.07.24.12.32.11.14.19.17.16.05.25.05.18-.02.25-.05.14-.09.19-.17.09-.19.11-.32c.03-.13.04-.29.04-.48v-.97z" />
    {props.children}
  </Icon>
);

export const Forward30 = memo(forwardRef(Forward30Path));

const Replay30Path: FC = (props, ref) => (
  <Icon {...props} ref={ref} viewBox="2 2 20 20">
    <path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z" />
    <path d="M9.56 13.49h.45c.21 0 .37-.05.48-.16s.16-.25.16-.43c0-.08-.01-.15-.04-.22s-.06-.12-.11-.17-.11-.09-.18-.11-.16-.04-.25-.04c-.08 0-.15.01-.22.03s-.13.05-.18.1-.09.09-.12.15-.05.13-.05.2h-.85c0-.18.04-.34.11-.48s.17-.27.3-.37.27-.18.44-.23.35-.08.54-.08c.21 0 .41.03.59.08s.33.13.46.23.23.23.3.38.11.33.11.53c0 .09-.01.18-.04.27s-.07.17-.13.25-.12.15-.2.22-.17.12-.28.17c.24.09.42.21.54.39s.18.38.18.61c0 .2-.04.38-.12.53s-.18.29-.32.39-.29.19-.48.24-.38.08-.6.08c-.18 0-.36-.02-.53-.07s-.33-.12-.46-.23-.25-.23-.33-.38-.12-.34-.12-.55h.85c0 .08.02.15.05.22s.07.12.13.17.12.09.2.11.16.04.25.04c.1 0 .19-.01.27-.04s.15-.07.2-.12.1-.11.13-.18.04-.15.04-.24c0-.11-.02-.21-.05-.29s-.08-.15-.14-.2-.13-.09-.22-.11-.18-.04-.29-.04h-.47v-.65zM15.3 14.24c0 .32-.03.6-.1.82s-.17.42-.29.57-.28.26-.45.33-.37.1-.59.1-.41-.03-.59-.1-.33-.18-.46-.33-.23-.34-.3-.57-.11-.5-.11-.82v-.74c0-.32.03-.6.1-.82s.17-.42.29-.57.28-.26.45-.33.37-.1.59-.1.41.03.59.1.33.18.46.33.23.34.3.57.11.5.11.82v.74zm-.85-.86c0-.19-.01-.35-.04-.48s-.07-.23-.12-.31-.11-.14-.19-.17-.16-.05-.25-.05-.18.02-.25.05-.14.09-.19.17-.09.18-.12.31-.04.29-.04.48v.97c0 .19.01.35.04.48s.07.24.12.32.11.14.19.17.16.05.25.05.18-.02.25-.05.14-.09.19-.17.09-.19.11-.32c.03-.13.04-.29.04-.48v-.97z" />
    {props.children}
  </Icon>
);

export const Replay30 = memo(forwardRef(Replay30Path));

const StopPath: FC = (props, ref) => (
  <Icon {...props} ref={ref} viewBox="2 2 20 20">
    <path d="M6 6h12v12H6z" />
    {props.children}
  </Icon>
);

export const Stop = memo(forwardRef(StopPath));

const PlayPath: FC = (props, ref) => (
  <Icon {...props} ref={ref} viewBox="2 2 20 20">
    <path d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    {props.children}
  </Icon>
);

export const Play = memo(forwardRef(PlayPath));

const PausePath: FC = (props, ref) => (
  <Icon {...props} ref={ref} viewBox="2 2 20 20">
    <path d="M9 16h2V8H9v8zm3-14C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-4h2V8h-2v8z" />
    {props.children}
  </Icon>
);

export const Pause = memo(forwardRef(PausePath));

const ExpandLessPath: FC = (props, ref) => (
  <Icon {...props} ref={ref} viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
    {props.children}
  </Icon>
);

export const ExpandLess = memo(forwardRef(ExpandLessPath));

const ExpandMorePath: FC = (props, ref) => (
  <Icon {...props} ref={ref} viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    {props.children}
  </Icon>
);

export const ExpandMore = memo(forwardRef(ExpandMorePath));

const CheckCirclePath: FC = (props, ref) => (
  <Icon {...props} ref={ref} viewBox="0 0 24 24">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
    {props.children}
  </Icon>
);

export const CheckCircle = memo(forwardRef(CheckCirclePath));

const InfoPath: FC = (props, ref) => (
  <Icon {...props} ref={ref} viewBox="0 0 24 24">
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    {props.children}
  </Icon>
);

export const Info = memo(forwardRef(InfoPath));

const NotificationsBellPath: FC = (props, ref) => (
  <Icon {...props} viewBox="0 0 24 24" ref={ref}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z" />
    {props.children}
  </Icon>
);

export const NotificationsBell = memo(forwardRef(NotificationsBellPath));

const MinusPath: FC = (props, ref) => (
  <Icon {...props} viewBox="0 0 24 24" ref={ref}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M19 13H5v-2h14v2z" />
    {props.children}
  </Icon>
);

export const Minus = memo(forwardRef(MinusPath));

const CirclePath: FC = (props, ref) => (
  <Icon {...props} viewBox="0 0 24 24" ref={ref}>
    <path d="M0 0h24v24H0z" fill="none" />
    <circle cx="12" cy="12" r="10" />
    {props.children}
  </Icon>
);

export const Circle = memo(forwardRef(CirclePath));
