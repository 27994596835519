import {
  Article,
  ArticleCE,
  ArticleCI,
  BusinessStreamName,
  Session,
  SessionCE,
  SessionCI,
  SessionsResponse,
} from '../api';
import { BusinessStream } from '../centres/businessStreamCheck';
import { isBusinessStreamCI } from '../centres/businessStreamCheck';
import { utcDate } from '../components/Calendar';

interface SittingValues {
  [key: string]: number;
}

export const SITTING_VALUES: SittingValues = { AM: 0, PM: 1, EV: 2, undefined: 3 };

type SessCompare<T> = (a: T, b: T) => number;
type Order<T> = [SessCompare<T>, 1 | -1];

export const isValidDownload = ({ download }: Session): boolean => !download || download.length > 0;

const compareKAD: SessCompare<Session> = (a, b) => +a.date - +b.date;
const compareAvailable: SessCompare<Session> = (a, b) => +a.available.from - +b.available.from;
const compareSitting: SessCompare<Session> = (a, b) => SITTING_VALUES[a.sitting] - SITTING_VALUES[b.sitting];
const compareDownload: SessCompare<Session> = (a, b) => +isValidDownload(a) - +isValidDownload(b);
const compareProduct: SessCompare<SessionCE> = (a, b) => a.article.product.localeCompare(b.article.product);
const compareSA: SessCompare<SessionCE> = (a, b) => +a.article.specialArrangement - +b.article.specialArrangement;
const compareQualification: SessCompare<SessionCI> = (a, b) =>
  a.article.qualificationShortName.localeCompare(b.article.qualificationShortName);
const compareSyllabusCode: SessCompare<SessionCI> = (a, b) =>
  a.article.assessmentId.localeCompare(b.article.assessmentId);
const compareComponentCode: SessCompare<SessionCI> = (a, b) =>
  a.article.componentId.localeCompare(b.article.componentId);
const compareSyllabusName: SessCompare<SessionCI> = (a, b) =>
  a.article.assessmentShortName.localeCompare(b.article.assessmentShortName);
const compareComponentName: SessCompare<SessionCI> = (a, b) =>
  a.article.componentShortName.localeCompare(b.article.componentShortName);

const compareSessions = <T>(order: Order<T>[]): SessCompare<T> => (a, b) => {
  for (const [func, direction] of order) {
    const res = func(a, b);
    if (res) return direction * res;
  }
  return 0;
};

export type SortKey = keyof typeof sort;

const sort = {
  English: compareSessions([
    [compareDownload, 1],
    [compareKAD, 1],
    [compareSitting, 1],
    [compareProduct, 1],
    [compareSA, 1],
    [compareAvailable, 1],
  ]),
  International: compareSessions([
    [compareDownload, 1],
    [compareKAD, 1],
    [compareQualification, 1],
    [compareSyllabusCode, 1],
    [compareComponentCode, 1],
    [compareSyllabusName, 1],
    [compareComponentName, 1],
    [compareAvailable, 1],
    [compareSitting, 1],
  ]),
};

type SortMap = Record<SortKey, Session[]>;

const createIdCE = (item: SessionCE<'raw'>): string => [item.article, item.date, item.sitting].join('-');
const createIdCI = (item: SessionCI<'raw'>): string => [item.article, item.date, item.series].join('-');
const createId = (item: Session<'raw'>, businessStream: BusinessStreamName): string => {
  return isBusinessStreamCI(businessStream)
    ? createIdCI(item as SessionCI<'raw'>)
    : createIdCE(item as SessionCE<'raw'>);
};

export const hash = (input: string, size = 3): string => {
  let list = input.split('').map((e) => e.charCodeAt(0) % 256);
  while (list.length > size) {
    const r = [];
    for (let i = 1; i < list.length; i++) {
      r[i - 1] = (list[i - 1] + list[i]) % 256;
    }
    list = r;
  }
  return btoa(String.fromCharCode(...list))
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};

const toSessions = ({ sessions, articles }: SessionsResponse, businessStream: BusinessStreamName): Session[] => {
  const idMapReducer = (acc: any, article: Article<'raw'>) => {
    if (isBusinessStreamCI(businessStream)) {
      const articleCI = article as ArticleCI<'raw'>;
      acc[articleCI.assessmentId] = acc[articleCI.assessmentId] || hash(articleCI.assessmentId);
    } else {
      const articleCE = article as ArticleCE<'raw'>;
      acc[articleCE.product] = acc[articleCE.product] || hash(articleCE.product);
    }
    return acc;
  };
  const idMap = (articles as Article<'raw'>[]).reduce<Record<string, string>>(idMapReducer, {});

  const articleMapReducer = (item: Article<'raw'>) => {
    if (isBusinessStreamCI(businessStream)) {
      const article = item as ArticleCI<'raw'>;
      // Filtering is not working if qualification short name has a dot
      article.qualificationShortName = article.qualificationShortName.replaceAll('.', '');
      return { ...article, pid: idMap[article.assessmentId] };
    } else {
      const article = item as ArticleCE<'raw'>;
      return { ...article, pid: idMap[article.product] };
    }
  };

  const articleMap = articles
    .map(articleMapReducer)
    .reduce<Record<string, Article>>((acc, item) => ((acc[item.id] = item), acc), {});

  const getSessionCommonData = (session: Session<'raw'>) => ({
    ...session,
    date: utcDate(session.date),
    available: {
      to: utcDate(session.available.to * 1000),
      from: utcDate(session.available.from * 1000),
    },
  });

  const processedSessions = sessions
    .filter(({ article }) => !!articleMap[article])
    .map((session) => ({
      ...getSessionCommonData(session),
      id: createId(session, businessStream),
      article: articleMap[session.article] as ArticleCE,
    }));

  return (processedSessions as Session<'processed'>[]).map((s) =>
    s.download ? { ...s, download: s.download.filter((d) => d.format.toUpperCase() !== 'FLAC') } : s,
  );
};

const toSortMap = (sessions: Session[], businessStream: BusinessStreamName): SortMap => {
  if (isBusinessStreamCI(businessStream)) {
    return {
      International: (sessions as SessionCI[]).slice().sort(sort[BusinessStream.INTERNATIONAL]),
    } as SortMap;
  }

  return {
    English: (sessions as SessionCE[]).slice().sort(sort[BusinessStream.ENGLISH]),
  } as SortMap;
};

export interface SessionsData {
  sorted: SortMap;
  items: Record<string, Session>;
}

export const readSessions = (response: SessionsResponse, businessStream: BusinessStreamName): SessionsData => {
  const sessions = toSessions(response, businessStream);
  const sorted = toSortMap(sessions, businessStream);
  const items = sessions.reduce<Record<string, Session>>((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
  return { sorted, items };
};
