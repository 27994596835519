/* eslint-disable */
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { AsyncTask, Falsy, useAsyncRun, useAsyncTask } from 'react-hooks-async';
import { useBootState } from '../boot';
import { useCentres } from '../centres';
import { isBusinessStreamCI } from '../centres/businessStreamCheck';
import { sleep } from '../util/helpers';
import { BusinessStreamName, SignatureResponse, TokenResponse } from './models';
import { Result, useRequest } from './use-request';

export function getBaseURL(businessStream: BusinessStreamName): string {
  const API_CE_DOMAIN = window?.env?.API_CE_DOMAIN || "";
  const API_CI_DOMAIN = window?.env?.API_CI_DOMAIN || "";
  if (isBusinessStreamCI(businessStream)) {
    return API_CI_DOMAIN;
  }
  return API_CE_DOMAIN;
}

interface Params {
  token: TokenResponse;
  filename: string;
}
const businessStreamSignDownload = (businessStream: BusinessStreamName, isZip: boolean) => {
  return async (
    abortController: AbortController,
    { filename, token }: Params,
  ): Promise<SignatureResponse> => {
    const source = Axios.CancelToken.source();
    abortController.signal.addEventListener('abort', () => {
      source.cancel('canceled');
    });
 
    let path = '/sign/';
  
    if (isZip) {
      path = '/sign_zip/';
    }
    
    try {
      const end = Date.now() + 60000 * 5;
      while (end > Date.now()) {
        const response: AxiosResponse<SignatureResponse> = await Axios({
          baseURL: getBaseURL(businessStream), 
          url: `${path}${filename}`,
          headers: { Authorization: `Bearer ${token.id_token}` },
          cancelToken: source.token,
        });
  
        const { data } = response;
        if (data.retry_in) await sleep(data.retry_in * 1000);
        else return data;
      }
  
      throw new Error(`${filename} file signing timeout`);
    } catch (e: any) {
      throw Axios.isCancel(e) ? Object.assign(new Error(e.message), { name: 'AbortError' }) : e;
    }
  };
}

export const useDownloadReport = async (token: TokenResponse, query: string, businessStream: BusinessStreamName): Promise<any> => {
  return await Axios({
    baseURL: getBaseURL(businessStream),
    url: `/reports${query}`,
    method: 'GET',
    headers: { Authorization: `Bearer ${token.id_token}` },
    responseType: 'blob',
  });
};

export interface SignedDownloadParams {
  filename: string;
  isZip: boolean;
}

const defaultParams: Params = {
  filename: '',
  token: { access_token: '', expires_in: 0, id_token: '', refresh_token: '', token_type: '' },
};

export const useSignedDownload = (params: SignedDownloadParams | Falsy): AsyncTask<SignatureResponse, [Params]> => {
  const boot = useBootState();
  const { businessStream } = useCentres();
  const token = !boot.loading && boot.token;
  const signDownload = useMemo(() => {
    return businessStreamSignDownload(businessStream, (params as SignedDownloadParams)?.isZip || false);
  }, [businessStream]);
  const task = useAsyncTask(signDownload);
  const rparams = useMemo(() => params && token && { filename: params.filename, token }, [params, token]);
  useAsyncRun(rparams && task, rparams || defaultParams);
  return task;
};

export const useAPIRequest = <T>(input: AxiosRequestConfig | Falsy): Result<T> => {
  const { businessStream } = useCentres();
  if (input) {
    input.baseURL = input.baseURL || getBaseURL(businessStream);
  }
  return useRequest(useMemo(() => input && { ...input }, [input]));
};

export const useAuthRequest = <T>(input: AxiosRequestConfig | Falsy): Result<T> => {
  const boot = useBootState();
  const token = !boot.loading && boot.token;
  return useAPIRequest(
    useMemo(
      () => input && token && { ...input, headers: { ...input.headers, Authorization: `Bearer ${token.id_token}` } },
      [input, token],
    ),
  );
};
