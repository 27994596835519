import { createContext, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useBootState, useIsInternalCompliance } from '../../boot';
import { Tab, TabInformation } from '../../components/DatePicker';
import { Article } from '../../components/ReportsFilterComboBox';
import { Group, Option } from '../../components/FilterComboBox';
import { useProvider } from '../../util/useProvider';
import { NotificationReportGenerationFailedMessage } from '../../notifications/views';
import { buildDateQuery, format, serialize } from '../../sessions/session-filter-util';
import { useNotification } from '../../notifications';
import { useDownloadReport } from '../../api';
import { useCentres } from '../../centres';
import { isBusinessStreamCE } from '../../centres/businessStreamCheck';
import {
  readCentreOptions,
  buildArticleQuery,
  buildCentreQuery,
  parseArticleSearchQuery,
  parseCentresSearchQuery,
  parseReportsDateQuery,
  getSearchParams,
} from './reports-util';
import { useArticlesProvider } from './ArticlesProvider';
import { Article as ReportArticle } from '../../components/ReportsFilterComboBox';
import { utcDate } from '../../components/Calendar';
import { ArticleCE, ArticleCI } from '../../api/models';

export type TabId = 'CENTRE' | 'ARTICLE' | 'ALL';

export interface ReportTab {
  id: TabId;
  name: string;
}

export interface ReportDatePickerTab extends Tab {
  reportId?: Lowercase<TabId>;
}

interface ArticleComboBox {
  articleOptions: Article[];
  focused: boolean;
  setFocusedContainer: (value: boolean) => void;
  onSelectArticle: (id: string, name: string) => void;
  selectedArticle: Article | null;
}

interface CentresComboBox {
  centreOptions: Option[];
  selectedCentres: Option[];
  setSelectedCentre: (selected: Option[]) => void;
  groups: Group[];
}

interface ReportsContextProps {
  articleComboBox: ArticleComboBox;
  centresComboBox: CentresComboBox;
  datePicker: DatePicker;
  onReportDownloadClick: () => void;
  setTabIdValue: (id: string) => void;
  tabId: TabId;
  tabList: ReportTab[];
}

interface DatePicker {
  tabs: TabInformation[];
  onRangeSelect: (selectedRange: ReportDatePickerTab | null) => void;
  selectedRange: ReportDatePickerTab | null;
  startDate: string;
  maxDays: number;
}

const START_DATE = '2020-01-01';
const MAX_DAYS = 100;

const defaultTabList: ReportTab[] = [
  {
    id: 'ARTICLE',
    name: 'By article',
  },
];

const complianceUserTabList: ReportTab[] = [
  {
    id: 'CENTRE',
    name: 'By centre',
  },
  {
    id: 'ALL',
    name: 'All downloads',
  },
];

const ReportsContext = createContext<ReportsContextProps>({
  articleComboBox: {
    selectedArticle: null,
    onSelectArticle: () => {},
    setFocusedContainer: () => {},
    focused: false,
    articleOptions: [],
  },
  centresComboBox: {
    selectedCentres: [],
    setSelectedCentre: () => {},
    groups: [],
    centreOptions: [],
  },
  datePicker: {
    onRangeSelect: () => {},
    selectedRange: null,
    tabs: [],
    startDate: '',
    maxDays: 0,
  },
  onReportDownloadClick: () => {},
  setTabIdValue: () => {},
  tabId: 'ARTICLE',
  tabList: defaultTabList,
});

export const useReports = (): ReportsContextProps => useContext(ReportsContext);

const tabs: TabInformation[] = [{ id: 'date', name: 'Download date' }];
const groups: Group[] = [{ name: 'centre', label: 'Centre' }];

const getActiveTab = (report: string | null, tabList: ReportTab[]): TabId | null => {
  const activeTab = (report && tabList.find(({ id }) => report.includes(id.toLowerCase()))) || null;
  return activeTab && activeTab.id;
};

export const ReportsProvider: FC = ({ children }) => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const [focused, setFocused] = useState<boolean>(false);
  const isInternalCompliance = useIsInternalCompliance();
  const tabList = useMemo(() => [...defaultTabList, ...(isInternalCompliance ? complianceUserTabList : [])], [
    isInternalCompliance,
  ]);
  const [tabId, setTabId] = useState<TabId>('ARTICLE');
  const report: TabId = useMemo(() => getActiveTab(params.get('report'), tabList) || 'ARTICLE', [params, tabList]);

  useEffect(() => {
    setTabId(report);
  }, [report]);

  const boot = useBootState();
  const history = useHistory();
  const { articles } = useArticlesProvider();
  const { centresList, businessStream } = useCentres();
  const articleOptions: ReportArticle[] = useMemo(() => {
    if (articles) {
      return isBusinessStreamCE(businessStream)
        ? (articles as ArticleCE[]).map((article) => ({ id: article.id, name: article.product }))
        : (articles as ArticleCI[]).map((article) => ({ id: article.id, name: article.qualificationShortName }));
    }
    return articles;
  }, [articles, businessStream]);

  const { dispatch: dispatchNotification, messages, assignMessage } = useNotification();

  const token = useMemo(() => !boot.loading && boot.token, [boot]);
  const centreOptions = useMemo(
    () =>
      (centresList &&
        readCentreOptions(
          centresList
            .filter((c) => c.productGroup?.includes(businessStream))
            .map((centre) => ({ id: centre.id, name: centre.name })),
        )) ||
      [],
    [centresList, businessStream],
  );

  const selectedRange = useMemo(
    () => parseReportsDateQuery(params.get('d'), params.get('report'), START_DATE, MAX_DAYS),
    [params],
  );

  const selectedArticle = useMemo(() => parseArticleSearchQuery(params.get('a'), articleOptions), [
    params,
    articleOptions,
  ]);
  const selectedCentres = useMemo(() => parseCentresSearchQuery(params.get('c'), centreOptions) || ([] as Option[]), [
    params,
    centreOptions,
  ]);

  const setTabIdValue = useCallback(
    (id: string) => {
      const currentTab = tabList.find((tab) => tab.id === id);
      if (currentTab) setTabId(currentTab.id);
    },
    [tabList],
  );

  const setSelectedArticle = useCallback(
    (selected: Article) => {
      const { pathname, search } = history.location;
      const params = getSearchParams(search, tabId);

      const query = buildArticleQuery(selected);

      if (!query) params.delete('a');
      else params.set('a', query);

      history.push(`${pathname}?${params}`);
    },
    [history, tabId],
  );

  const setSelectedCentre = useCallback(
    (selected: Option[]) => {
      const { pathname, search } = history.location;
      const params = getSearchParams(search, tabId);

      const query = buildCentreQuery(selected);

      if (!query) params.delete('c');
      else params.set('c', query);

      history.push(`${pathname}?${params}`);
    },
    [history, tabId],
  );

  const onRangeSelect = useCallback(
    (selectedRange: Tab | null) => {
      const { pathname, search } = history.location;
      const params = getSearchParams(search, tabId);

      const query = selectedRange && buildDateQuery(selectedRange);

      if (!query) params.delete('d');
      else params.set('d', query);

      history.push(`${pathname}?${params}`);
    },
    [history, tabId],
  );

  const onSelectArticle = useCallback(
    (id: string, name: string) => {
      const DTO = id && name && { id: id, name: name };
      setSelectedArticle(DTO || ({} as Article));
    },
    [setSelectedArticle],
  );

  const onReportDownloadClick = useCallback(() => {
    if (!selectedRange || !token || !token.id_token) return;

    if (selectedRange.reportId !== tabId.toLowerCase()) return;
    if (tabId === 'ARTICLE' && (!selectedArticle || !selectedArticle.id)) return;
    if (tabId === 'CENTRE' && !selectedCentres.length) return;

    const { from, to } = selectedRange;

    const dateFrom = from ? serialize(format(from)) : null;
    const dateTo = to ? serialize(format(to)) : null;

    if ((!dateFrom && !dateTo) || !dateFrom) return;

    const centreIds = selectedCentres.map((centre) => centre.value);
    const joinedCentreIds = centreIds.join('*');
    const selectedQueryItem = tabId === 'ARTICLE' && selectedArticle ? selectedArticle.id : joinedCentreIds;

    const query = dateTo
      ? `?type=${tabId.toLowerCase()}&q=${selectedQueryItem}&d=from.${dateFrom}*to.${dateTo}`
      : `?type=${tabId.toLowerCase()}&q=${selectedQueryItem}&d=from.${dateFrom}*to.${dateFrom}`;

    useDownloadReport(token, query, businessStream)
      .then((response) => {
        if (!response.data.size) {
          const dateFromForNotification = utcDate(dateFrom).format('DD MMM YYYY');
          const dateToForNotification = dateTo?.length ? utcDate(dateTo).format('DD MMM YYYY') : null;

          assignMessage(
            <NotificationReportGenerationFailedMessage
              articleNumber={selectedArticle ? selectedArticle.id : undefined}
              centreIds={centreIds}
              dateFrom={dateFromForNotification}
              dateTo={dateToForNotification === dateFromForNotification ? null : dateToForNotification}
            />,
            'NO_DOWNLOAD_ACTIVITY',
          );

          dispatchNotification([
            'ADD',
            { title: 'No download activity found', body: messages.NO_DOWNLOAD_ACTIVITY },
            'ERROR',
          ]);
          return;
        }

        const fileName = response.headers['content-disposition'].split('filename=')[1];
        const contentType = response.headers['content-type'];

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(new Blob([response.data], { type: contentType }), fileName);
          return;
        }

        const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => console.error(err));
  }, [
    assignMessage,
    dispatchNotification,
    messages.NO_DOWNLOAD_ACTIVITY,
    selectedArticle,
    selectedCentres,
    selectedRange,
    tabId,
    token,
    businessStream,
  ]);

  const setFocusedContainer = useCallback((value: boolean) => {
    setFocused(value);
  }, []);

  const value = useMemo<ReportsContextProps>(
    () => ({
      articleComboBox: {
        selectedArticle,
        onSelectArticle,
        setFocusedContainer,
        focused,
        articleOptions,
      },
      centresComboBox: {
        selectedCentres,
        setSelectedCentre,
        groups,
        centreOptions,
      },
      datePicker: { tabs, onRangeSelect, selectedRange, startDate: START_DATE, maxDays: MAX_DAYS },
      onReportDownloadClick,
      setTabIdValue,
      tabId,
      tabList,
    }),
    [
      articleOptions,
      centreOptions,
      focused,
      onRangeSelect,
      onReportDownloadClick,
      onSelectArticle,
      selectedArticle,
      selectedCentres,
      selectedRange,
      setFocusedContainer,
      setSelectedCentre,
      setTabIdValue,
      tabId,
      tabList,
    ],
  );

  return useProvider(ReportsContext, value, children);
};
