import dayjs from 'dayjs';
import { createRef, MutableRefObject, useMemo, useRef } from 'react';
import { CalendarSelected } from '../Calendar';
import { Action, ReducerState } from './types';

dayjs.locale('en-gb');

const refs = (): ReducerState['refs'] => ({ container: createRef(), clear: createRef() });

export const useReducerState = (): MutableRefObject<ReducerState> =>
  useRef(
    useMemo(
      (): ReducerState => ({
        open: false,
        focused: false,
        tabs: [],
        activeTabPanel: null,
        input: null,
        selected: null,
        refs: refs(),
      }),
      [],
    ),
  );

const getDefaultActiveId = (state: ReducerState) => {
  const { input, activeTabPanel, tabs } = state;
  return (input && input.id) || activeTabPanel || tabs[0].id;
};

export const reducer = (state: ReducerState, action: Action): ReducerState => {
  const { open, focused } = state;

  switch (action[0]) {
    case 'SHOW_DIALOG':
      return open ? state : { ...state, open: true, activeTabPanel: getDefaultActiveId(state) };

    case 'HIDE_DIALOG':
      return !open ? state : { ...state, open: false };

    case 'FOCUS_IN':
      return focused ? state : { ...state, focused: true };

    case 'FOCUS_OUTSIDE':
      return !focused ? state : { ...state, focused: false };

    case 'SET_SELECTED': {
      const [, input] = action;
      return { ...state, selected: input, input, activeTabPanel: getDefaultActiveId({ ...state, input }) };
    }

    case 'SET_TABS': {
      const [, tabs] = action;

      if (!tabs.length) {
        throw new Error('At least one tab declaration is required');
      }

      const activeTabPanel = getDefaultActiveId({ ...state, tabs });
      return { ...state, tabs, activeTabPanel };
    }

    case 'SELECTION_CHANGE':
      return onSelect(state, action[1]);

    case 'TAB_SELECT':
      return onTabSelect(state, action[1]);

    case 'CLEAR_SELECTION':
      return { ...state, selected: null };

    case 'CANCEL':
      return { ...state, open: false, selected: state.input, activeTabPanel: null };

    case 'RESET':
      return { ...state, open: false, selected: null };
  }
};

export const onSelect = (state: ReducerState, selected: CalendarSelected): ReducerState => {
  const { activeTabPanel } = state;
  if (!activeTabPanel) return state;
  return { ...state, selected: { id: activeTabPanel, from: selected[0], to: selected[1] } };
};

export const onTabSelect = (state: ReducerState, id: string): ReducerState => {
  const { activeTabPanel } = state;
  if (id !== activeTabPanel) return { ...state, activeTabPanel: id };
  return state;
};
