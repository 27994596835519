import { createContext, FC, useContext, useMemo } from 'react';
import { Role } from '../api/models';
import { useProvider } from '../util/useProvider';
import { AppBootResult, useAppBoot } from './useBoot';

export type BootContextProps = AppBootResult;

const Context = createContext<BootContextProps>({
  loading: true,
  dispatch: () => {
    throw new Error('Missing BootProvider');
  },
});

export const useBootState = (): BootContextProps => useContext(Context);

export const useIsHelpdesk = (): boolean => {
  const boot = useBootState();
  return (!boot.loading && boot.user && boot.user.isHelpdesk) || false;
};

export const useIsAdmin = (centreId: string | null): boolean => {
  const boot = useBootState();
  const roles =
    (centreId &&
      !boot.loading &&
      boot.user &&
      boot.user.centres &&
      boot.user.centres.find((c) => c.id === centreId)?.roles) ||
    null;

  return (roles && roles.some((r) => r === 'DELEGATED_ADMIN')) || false;
};

const availableRoles: Role[] = [
  'INTERNAL_HELPDESK',
  'EXAMS_OFFICER',
  'DELEGATED_ADMIN',
  'SUPERVISOR',
  'INTERNAL_COMPLIANCE',
];

export const useIsSupervisor = (centreId: string | null): boolean => {
  const boot = useBootState();

  const roles = useMemo(
    () =>
      (centreId &&
        !boot.loading &&
        boot.user &&
        boot.user.centres &&
        boot.user.centres.find((c) => c.id === centreId)?.roles) ||
      null,
    [boot, centreId],
  );
  const filteredRoles = useMemo(
    () => (roles && roles.filter((role) => availableRoles.some((aRole) => aRole === role))) || [],
    [roles],
  );

  const hasSingleRole = useMemo(() => filteredRoles.length === 1, [filteredRoles.length]);
  return useMemo(() => hasSingleRole && filteredRoles.some((r) => r === 'SUPERVISOR'), [hasSingleRole, filteredRoles]);
};

export const useIsInternalCompliance = (): boolean => {
  const boot = useBootState();
  return (!boot.loading && boot.user && boot.user.isInternalCompliance) || false;
};

export const useUserName = (): string | null => {
  const boot = useBootState();
  const token = (!boot.loading && boot.user) || null;
  return token && token.name;
};

export const useUserEmailHmac = (): string | null => {
  const boot = useBootState();
  const user = (!boot.loading && boot.user) || null;
  return (user && user.emailHmac) || null;
};

export const clearSessionStorageNotifications = (): void => {
  const notifications = Object.keys(sessionStorage).filter((key) => key.includes('notification-'));

  if (notifications && notifications.length > 0) {
    for (const key of notifications) {
      sessionStorage.removeItem(key);
    }
  }
};

export const clearBusinessUnitData = (): void => {
  if (sessionStorage.getItem('BU')) {
    sessionStorage.removeItem('BU');
  }
};

export const useMyCambridgeLink = (): string | null => {
  const boot = useBootState();
  const config = (!boot.loading && boot.config) || null;
  return (config && config.MY_CAMBRIDGE_LINK) || null;
};

export const useLogout = (): (() => void) | null => {
  const boot = useBootState();
  const config = (!boot.loading && boot.config) || null;
  const emailHmac = useUserEmailHmac();

  return useMemo(
    () =>
      config &&
      (() => {
        sessionStorage.removeItem('token');
        emailHmac && sessionStorage.removeItem(emailHmac);
        location.assign(config.MY_CAMBRIDGE_LINK);
      }),
    [config, emailHmac],
  );
};

export const BootProvider: FC = ({ children }) => useProvider(Context, useAppBoot(), children);
