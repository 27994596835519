import { createContext, FC, useContext, useMemo } from 'react';
import { useCurrentPage } from '../components/Pagination';
import { useProvider } from '../util/useProvider';
import { useSessionFilter } from './SessionFilterProvider';
import { useSessionSort } from './SessionSortProvider';
import { getSortedSessions } from './sessions-sort-util';
import { useSessions } from './SessionsProvider';
import { useCentres } from '../centres';
import { isBusinessStreamCI } from '../centres/businessStreamCheck';
import { Session } from '../api/models';

interface ListContextProps {
  list: string[] | null;
  totalPages: number;
}

const ListContext = createContext<ListContextProps>({ totalPages: 0, list: null });

export const useSessionList = (): ListContextProps => useContext(ListContext);

export const SessionListProvider: FC = ({ children }) => {
  const { businessStream } = useCentres();
  const currentPage = useCurrentPage();
  const pageSize = 25;
  const sessions = useSessions();
  const sortMap = sessions.data?.sorted;
  const sort: Session<'processed'>[] = useMemo(() => {
    if (sortMap && (sortMap?.English || sortMap?.International)) {
      return isBusinessStreamCI(businessStream) ? [...sortMap.International] : [...sortMap.English];
    } else return [];
  }, [sortMap, businessStream]);
  const sorted = useMemo(() => sessions.data && sort, [sessions.data, sort]);
  const {
    comboBox: { filter },
  } = useSessionFilter();

  const {
    sort: { order },
  } = useSessionSort();

  const dataList = useMemo(() => sorted && getSortedSessions(filter([...sorted]), order), [filter, order, sorted]);

  const lastPage = (dataList && Math.ceil(dataList.length / pageSize)) || 1;

  const sliced = useMemo(() => {
    if (currentPage > lastPage) return [];
    else return dataList && dataList.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  }, [dataList, currentPage, lastPage]);
  const list = useMemo(() => sliced && sliced.map((e) => e.id), [sliced]);

  const value = useMemo<ListContextProps>(() => ({ list, totalPages: lastPage }), [lastPage, list]);

  return useProvider(ListContext, value, children);
};
