import { ParsedIdToken } from '../api';
import { BusinessStream } from '../centres/businessStreamCheck';

export const canCallUseCustomers = (
  user: ParsedIdToken | null | false,
  businessStream: BusinessStream.ENGLISH | BusinessStream.INTERNATIONAL,
): boolean | undefined => {
  if (!user) return;
  if (user.isHelpdesk || user.isInternalCompliance) {
    const businessStreams = (user.businessStream?.split(',') || []).map((businessStreamName) =>
      businessStreamName.trim(),
    );
    return businessStreams.includes(businessStream);
  }
  return true;
};
