import { Dayjs } from 'dayjs';
import { FC, MouseEvent, useMemo } from 'react';
import { Message } from '../api';
import { utcDate } from '../components/Calendar';
import { DateFormat, DateTime } from '../components/DateTime';
import { Circle, Minus } from '../components/Icon';
import { MessageContainer, MessageContent, MessageTitle, MessageLink } from '../components/Message';
import { childTestID, WithIProps } from '../util/test-id';
import styles from './styles.module.scss';

interface MessageViewProps extends WithIProps<'div'> {
  item: Message;
  active: boolean;
  time: Dayjs;
  onMessageClick: (event: MouseEvent<HTMLElement>) => void;
  link?: string;
}

export const RemovalMessageView: FC<MessageViewProps> = ({ time, item, active, testID, onMessageClick, ...rest }) => {
  const messageTitle = useMemo(() => {
    if (item.product) {
      const { product, date, sitting } = item;
      return (
        <>
          {product} audio file for session <DateTime value={utcDate(date)} format={DateFormat.DATE} /> {sitting} was
          deleted
        </>
      );
    } else {
      const { assessmentId, qualificationShortName, date, componentId } = item;
      return (
        <>
          {qualificationShortName} {assessmentId} {componentId} audio file for session&nbsp;
          <DateTime value={utcDate(date)} format={DateFormat.DATE} /> was deleted
        </>
      );
    }
  }, [item]);

  const messageContent = useMemo(() => {
    if (item.product) {
      const { product, date, sitting } = item;
      return (
        <>
          The audio file for {product} on <DateTime value={utcDate(date)} format={DateFormat.DATE} /> {sitting} is due
          to be replaced, please delete any copies of the file you previously downloaded for this session, and download
          the replacement file once it becomes available.
        </>
      );
    } else {
      const { assessmentId, qualificationShortName, componentId, date } = item;
      return (
        <>
          The audio file for {qualificationShortName} {assessmentId} {componentId} on&nbsp;
          <DateTime value={utcDate(date)} format={DateFormat.DATE} /> is due to be replaced, please delete any copies of
          the file you previously downloaded for this session, and download the replacement file once it becomes
          available.
        </>
      );
    }
  }, [item]);

  const { id, hasRead } = item;
  return (
    <MessageContainer id={id} active={active} read={hasRead} testID={testID} {...rest}>
      <MessageTitle
        id={id}
        icon={hasRead ? <Minus /> : <Circle className={styles.errorCircle} />}
        onClick={onMessageClick}
        date={time}
        testID={childTestID(testID, `message-${id}`)}
        active={active}
        read={hasRead}
      >
        {messageTitle}
      </MessageTitle>
      <MessageContent id={id} active={active} testID={childTestID(testID, `message-${id}`)}>
        {messageContent}
      </MessageContent>
    </MessageContainer>
  );
};

export const ReplacementMessageView: FC<MessageViewProps> = ({
  link,
  time,
  item,
  active,
  testID,
  onMessageClick,
  ...rest
}) => {
  const messageTitle = useMemo(() => {
    if (item.product) {
      const { product, date, sitting } = item;
      return (
        <>
          {product} audio file for session <DateTime value={utcDate(date)} format={DateFormat.DATE} /> {sitting} was
          replaced
        </>
      );
    } else {
      const { assessmentId, date, qualificationShortName, componentId } = item;
      return (
        <>
          {qualificationShortName} {assessmentId} {componentId} audio file for session&nbsp;
          <DateTime value={utcDate(date)} format={DateFormat.DATE} /> was replaced
        </>
      );
    }
  }, [item]);

  const messageContent = useMemo(() => {
    if (item.product) {
      const { product, date, sitting, specialArrangement, id } = item;
      return (
        <>
          The audio file for {product} on <DateTime value={utcDate(date)} format={DateFormat.DATE} /> {sitting} has been
          replaced, please delete any copies of the file you previously downloaded for this session, and download the
          replacement file.
          {link && (
            <MessageLink
              product={product}
              sitting={sitting}
              date={date ? new Date(date) : new Date()}
              specialArr={specialArrangement}
              link={link}
              testID={childTestID(testID, `message-link-${id}`)}
            >
              Go to file
            </MessageLink>
          )}
        </>
      );
    } else {
      const { assessmentId, date, id, qualificationShortName, componentId } = item;
      return (
        <>
          The audio file for {qualificationShortName} {assessmentId} {componentId} on&nbsp;
          <DateTime value={utcDate(date)} format={DateFormat.DATE} /> has been replaced, please delete any copies of the
          file you previously downloaded for this session, and download the replacement file.
          {link && (
            <MessageLink
              date={date ? new Date(date) : new Date()}
              assessmentId={assessmentId}
              qualificationShortName={qualificationShortName}
              componentId={componentId}
              link={link}
              testID={childTestID(testID, `message-link-${id}`)}
            >
              Go to file
            </MessageLink>
          )}
        </>
      );
    }
  }, [item, testID, link]);

  const { id, hasRead } = item;
  return (
    <MessageContainer id={id} active={active} read={hasRead} testID={testID} {...rest}>
      <MessageTitle
        id={id}
        icon={hasRead ? <Minus /> : <Circle className={styles.errorCircle} />}
        onClick={onMessageClick}
        date={time}
        testID={childTestID(testID, `message-${id}`)}
        active={active}
        read={hasRead}
      >
        {messageTitle}
      </MessageTitle>
      <MessageContent id={id} active={active} testID={childTestID(testID, `message-${id}`)}>
        {messageContent}
      </MessageContent>
    </MessageContainer>
  );
};
