import {
  createContext,
  createElement,
  Dispatch,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import {
  BusinessStreamName,
  Result,
  Session,
  SessionCE,
  SessionCI,
  SignatureResponse,
  useRequest,
  useSignedDownload,
} from '../api';
import { useNotification } from '../notifications';
import { useSessions } from '../sessions';
import Axios, { AxiosError } from 'axios';
import { sleep } from '../util/helpers';
import { utcDate, toDateString } from '../components/Calendar';
import { useCentres } from '../centres';
import { isBusinessStreamCI } from '../centres/businessStreamCheck';

export type SignatureContextProps = {
  state: State;
  isDownloadActive: boolean;
  download: (filename: string, isZip?: boolean) => void;
  dispatch: Dispatch<Action>;
};

const SignatureContext = createContext<SignatureContextProps>({
  state: { requests: {}, responses: {} },
  isDownloadActive: false,
  download: () => {
    throw new Error('Missing `SignatureProvider`');
  },
  dispatch: () => {
    throw new Error('Missing `SignatureProvider`');
  },
});

export const useSignature = (): SignatureContextProps => useContext(SignatureContext);
export const useSignatureItem = (filename: string): SignatureItem => {
  const {
    state: { requests, responses },
  } = useSignature();

  const pending = !!requests[filename];
  const response = responses[filename];
  const started = pending || !!response;
  return useMemo(() => ({ filename, started, pending, ...(response || {}) }), [filename, pending, response, started]);
};

export type SignatureItem = {
  filename: string;
  started: boolean;
  pending: boolean;
  result?: string;
  error?: string;
};
type State = {
  requests: { [filename: string]: { filename: string; isZip: boolean } };
  responses: { [filename: string]: { result?: string; error?: string } };
};

type Action =
  | ['SIGN', string, boolean]
  | ['RESPONSE', string, SignatureResponse]
  | ['REMOVE_REQUEST', string]
  | ['RESET_STATE'];

const reducer = (state: State, action: Action): State => {
  switch (action[0]) {
    case 'SIGN': {
      const [, filename, isZip] = action;
      if (filename in state.requests) return state;
      const response = state.responses[filename];
      const responses = response ? { ...state.responses } : state.responses;
      delete responses[filename];
      return { responses, requests: { ...state.requests, [filename]: { filename, isZip } } };
    }

    case 'RESPONSE': {
      const [, filename, { result, error }] = action;
      const requests = { ...state.requests };
      delete requests[filename];
      return { responses: { ...state.responses, [filename]: { result, error } }, requests };
    }

    case 'REMOVE_REQUEST': {
      const [, filename] = action;
      const requests = { ...state.requests };
      delete requests[filename];
      return { ...state, requests };
    }

    case 'RESET_STATE': {
      return { requests: {}, responses: {} };
    }
  }
};

const useSessionProduct = (filename: string): string => {
  const { data } = useSessions();
  const session =
    data && Object.values(data.items).find((s) => s.download && s.download.find((d) => d.filename === filename));
  return useMemo(() => (session ? (session as SessionCE<'processed'>).article.product : ''), [session]);
};

const useSessionUniqueIdentifier = (filename: string, businessStream: BusinessStreamName): string => {
  const { data } = useSessions();
  const session =
    data && Object.values(data.items).find((s) => s.download && s.download.find((d) => d.filename === filename));

  const articleNo = session?.article.id;
  const date = toDateString(utcDate(session?.date));
  const sitting = (session as SessionCE<'processed'>)?.sitting;
  const series = (session as SessionCI<'processed'>)?.series;

  return useMemo(
    () =>
      session
        ? isBusinessStreamCI(businessStream)
          ? `${articleNo}-${date}-${series}`
          : `${articleNo}-${date}-${sitting}`
        : '',
    [session, articleNo, date, sitting, series, businessStream],
  );
};

const SignTask: FC<{
  filename: string;
  dispatch: Dispatch<Action>;
  businessStream: BusinessStreamName;
  isZip: boolean;
}> = ({ filename, dispatch, businessStream, isZip }) => {
  const product = useSessionProduct(filename);
  const uniqueIdentifier = useSessionUniqueIdentifier(filename, businessStream);
  const { dispatch: dispatchNotification, messages } = useNotification();
  const request = useSignedDownload(useMemo(() => ({ filename, isZip }), [filename, isZip]));

  useEffect(() => {
    if (request.result) dispatch(['RESPONSE', filename, request.result]);
  }, [dispatch, filename, request]);

  const loadingNotificationId = `notification-${uniqueIdentifier}`;

  useEffect(() => {
    if (request.error) {
      dispatch(['REMOVE_REQUEST', filename]);
      const error = request.error as AxiosError<{ error: string } | null>;

      if (error.response && error.response.data) {
        dispatchNotification(['REMOVE', loadingNotificationId]);
        switch (error.response.data.error) {
          case 'file_not_found':
            dispatchNotification([
              'ADD',
              { title: `${product} failed to download`, body: messages.FILE_NOT_FOUND },
              'ERROR',
            ]);
            break;

          case 'incorrect_filename':
            dispatchNotification([
              'ADD',
              { title: `${product} failed to download`, body: messages.INCORRECT_FILENAME },
              'ERROR',
            ]);
            break;

          case 'not_in_download_window':
            dispatchNotification([
              'ADD',
              { title: `${product} failed to download`, body: messages.NOT_IN_DOWNLOAD_WINDOW },
              'ERROR',
            ]);
            break;

          case 'access_denied':
            dispatchNotification([
              'ADD',
              { title: `${product} failed to download`, body: messages.ACCESS_DENIED },
              'ERROR',
            ]);
            break;

          default:
            dispatchNotification([
              'ADD',
              { title: `${product} failed to download`, body: messages.WATERMARKING_FAILED },
              'ERROR',
            ]);
            break;
        }
      } else {
        dispatchNotification(['REMOVE', loadingNotificationId]);
        dispatchNotification([
          'ADD',
          { title: `${product} failed to download`, body: messages.WATERMARKING_FAILED },
          'ERROR',
        ]);
      }
    }
  }, [dispatch, dispatchNotification, filename, messages, product, request, loadingNotificationId]);

  return null;
};

const DownloadTask: FC<{ filename: string; businessStream: BusinessStreamName }> = ({ filename, businessStream }) => {
  const {
    state: { responses },
  } = useSignature();

  const product = useSessionProduct(filename);
  const uniqueIdentifier = useSessionUniqueIdentifier(filename, businessStream);
  const { dispatch, messages } = useNotification();
  const response = responses[filename];
  const result = response?.result;

  const { error: linkCheckError, result: linkCheckResult }: Result<string> = useRequest(
    useMemo(() => result && { method: 'GET', url: result, headers: { Range: 'bytes=0-0' } }, [result]),
  );

  const loadingNotificationId = `notification-${uniqueIdentifier}`;

  useEffect(() => {
    if (result && linkCheckResult) {
      dispatch(['REMOVE', loadingNotificationId]);
      location.assign(result);
    }
  }, [dispatch, linkCheckResult, result, loadingNotificationId]);

  useEffect(() => {
    if (linkCheckError) {
      dispatch(['REMOVE', loadingNotificationId]);
      dispatch(['ADD', { title: `${product} failed to download`, body: messages.FILE_NOT_FOUND }, 'ERROR']);
    }
  }, [dispatch, linkCheckError, messages, product, loadingNotificationId]);

  return null;
};

export const SignatureProvider: FC = ({ children }) => {
  const sessions = useSessions();
  const { businessStream } = useCentres();
  const [state, dispatch] = useReducer(
    reducer,
    useMemo(() => ({ requests: {}, responses: {} }), []),
  );

  const download = useCallback((filename: string, isZip?: boolean) => {
    dispatch(['SIGN', filename, !!isZip]);
  }, []);

  const shash = Object.keys(state.requests).join(' ');
  const signinig = useMemo(() => (shash ? shash.split(' ').map((h) => state.requests[h]) : []), [
    shash,
    state.requests,
  ]);

  const dhash = Object.keys(state.responses).join(' ');
  const downloading = useMemo(() => (dhash ? dhash.split(' ') : []), [dhash]);

  const signatures = useMemo(
    () =>
      signinig.map(({ filename, isZip }) =>
        createElement(SignTask, { filename, isZip, dispatch, businessStream: businessStream, key: filename }),
      ),
    [signinig, businessStream],
  );

  const downloads = useMemo(
    () =>
      downloading.map((filename) =>
        createElement(DownloadTask, { filename, businessStream: businessStream, key: filename }),
      ),
    [downloading, businessStream],
  );

  const filterDownloadActive = useCallback(
    (session: Session<'processed'>[] | undefined) =>
      session &&
      session
        .filter((item) => item.download && item.download.length)
        .map((item) => {
          const arr = [];
          if (item.download) {
            for (const download of item.download) {
              arr.push(download);
            }
          }
          return arr;
        })
        .flat()
        .map((item) => item?.filename)
        .some((item) => item && !!state.requests[item]),
    [state.requests],
  );

  const isDownloadActive =
    useMemo(
      () =>
        isBusinessStreamCI(businessStream)
          ? filterDownloadActive(sessions?.data?.sorted?.International)
          : filterDownloadActive(sessions?.data?.sorted?.English),
      [sessions, businessStream, filterDownloadActive],
    ) || false;

  const value = useMemo<SignatureContextProps>(() => ({ state, isDownloadActive, download, dispatch }), [
    download,
    state,
    isDownloadActive,
    dispatch,
  ]);

  return createElement(SignatureContext.Provider, { value }, children, signatures, downloads);
};

Axios.interceptors.response.use(undefined, async (error: AxiosError | Error) => {
  if (
    'isAxiosError' in error &&
    error.code === 'ECONNABORTED' &&
    (error.config.url?.startsWith('/sign') || error.config.url?.includes('/downloads/signed'))
  ) {
    await sleep(100);
    return Axios.request(error.config);
  }

  return Promise.reject(error);
});
