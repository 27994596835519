import { Session, SessionCE, SessionCI, SupervisorSession, SupervisorSessionCE } from '../api';
import { SITTING_VALUES } from './readSessions';

interface SearchProps {
  [key: string]: string;
}

interface SearchValues {
  key: string;
  order: string;
  index: number;
}

export interface Order {
  key: string;
  order: string;
  orderNo: number;
  index: number;
}

export const getSearch = (): SearchProps => {
  return window.location.search
    .substr(1)
    .split('&')
    .map((x) => x.split('='))
    .filter(([k, v]) => k && v)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: decodeURIComponent(v) }), {});
};

export const searchSortValues = (): SearchValues[] => {
  const search = getSearch();

  if (search) {
    for (const [key, value] of Object.entries(search)) {
      if (key === 'sort') {
        return String(value)
          .split('*')
          .map((x, index) => ({ key: x.split('.')[0], order: x.split('.')[1], index: index + 1 }));
      }
    }
  }

  return [];
};

export const customSort = (sessions: Session[], orderToSort: Record<string, string>[]): Session[] => {
  const sortArr: ((a: Session, b: Session) => any)[] = [];

  let sessionsWithNoDownload: Session[] = [];

  for (const order of orderToSort) {
    switch (order.key) {
      case 'KAD':
      case 'examDate': {
        if (order.order === 'asc') {
          sortArr.push((a: Session, b: Session) => +a.date - +b.date);
        }
        if (order.order === 'desc') {
          sortArr.push((a: Session, b: Session) => +b.date - +a.date);
        }
        break;
      }
      case 'product': {
        if (order.order === 'asc') {
          sortArr.push((a, b) => (a as SessionCE).article.product.localeCompare((b as SessionCE).article.product));
        }
        if (order.order === 'desc') {
          sortArr.push((a: Session, b: Session) =>
            (b as SessionCE).article.product.localeCompare((a as SessionCE).article.product),
          );
        }
        break;
      }
      case 'sitting': {
        if (order.order === 'asc') {
          sortArr.push(
            (a: Session, b: Session) =>
              SITTING_VALUES[(a as SessionCE).sitting] - SITTING_VALUES[(b as SessionCE).sitting],
          );
        }
        if (order.order === 'desc') {
          sortArr.push(
            (a: Session, b: Session) =>
              SITTING_VALUES[(b as SessionCE).sitting] - SITTING_VALUES[(a as SessionCE).sitting],
          );
        }
        break;
      }
      case 'specialArrangement': {
        if (order.order === 'asc') {
          sortArr.push(
            (a: Session, b: Session) =>
              +(a as SessionCE).article.specialArrangement - +(b as SessionCE).article.specialArrangement,
          );
        }
        if (order.order === 'desc') {
          sortArr.push(
            (a: Session, b: Session) =>
              +(b as SessionCE).article.specialArrangement - +(a as SessionCE).article.specialArrangement,
          );
        }
        break;
      }
      case 'download': {
        sessionsWithNoDownload = sessions.filter((s) => s.download && s.download.length === 0);

        if (sessionsWithNoDownload && sessionsWithNoDownload.length > 0) {
          sessions = sessions.filter((s) => !s.download || s.download.length > 0);
        }

        if (order.order === 'asc') {
          sortArr.push((a: Session, b: Session) => +a.available.from - +b.available.from);
        }
        if (order.order === 'desc') {
          sortArr.push((a: Session, b: Session) => +b.available.from - +a.available.from);
        }
        break;
      }
      case 'qualificationShortName': {
        if (order.order === 'asc') {
          sortArr.push((a, b) =>
            (a as SessionCI).article.qualificationShortName.localeCompare(
              (b as SessionCI).article.qualificationShortName,
            ),
          );
        }
        if (order.order === 'desc') {
          sortArr.push((a: Session, b: Session) =>
            (b as SessionCI).article.qualificationShortName.localeCompare(
              (a as SessionCI).article.qualificationShortName,
            ),
          );
        }
        break;
      }
      case 'syllabusCode': {
        if (order.order === 'asc') {
          sortArr.push((a, b) =>
            (a as SessionCI).article.assessmentId.localeCompare((b as SessionCI).article.assessmentId),
          );
        }
        if (order.order === 'desc') {
          sortArr.push((a: Session, b: Session) =>
            (b as SessionCI).article.assessmentId.localeCompare((a as SessionCI).article.assessmentId),
          );
        }
        break;
      }
      case 'componentCode': {
        if (order.order === 'asc') {
          sortArr.push((a, b) =>
            (a as SessionCI).article.componentId.localeCompare((b as SessionCI).article.componentId),
          );
        }
        if (order.order === 'desc') {
          sortArr.push((a: Session, b: Session) =>
            (b as SessionCI).article.componentId.localeCompare((a as SessionCI).article.componentId),
          );
        }
        break;
      }
      case 'syllabusName': {
        if (order.order === 'asc') {
          sortArr.push((a, b) =>
            (a as SessionCI).article.assessmentShortName.localeCompare((b as SessionCI).article.assessmentShortName),
          );
        }
        if (order.order === 'desc') {
          sortArr.push((a: Session, b: Session) =>
            (b as SessionCI).article.assessmentShortName.localeCompare((a as SessionCI).article.assessmentShortName),
          );
        }
        break;
      }
      case 'componentName': {
        if (order.order === 'asc') {
          sortArr.push((a, b) =>
            (a as SessionCI).article.componentShortName.localeCompare((b as SessionCI).article.componentShortName),
          );
        }
        if (order.order === 'desc') {
          sortArr.push((a: Session, b: Session) =>
            (b as SessionCI).article.componentShortName.localeCompare((a as SessionCI).article.componentShortName),
          );
        }
        break;
      }
      default: {
        return sessions;
      }
    }
  }

  sessions.sort((a, b) => {
    for (let i = 0; i < sortArr.length; i++) {
      const res = sortArr[i](a, b);

      if (res === 0) {
        continue;
      }
      return res;
    }
  });

  if (sessionsWithNoDownload && sessionsWithNoDownload.length > 0) {
    for (const excluded of sessionsWithNoDownload.reverse()) {
      sessions.unshift(excluded);
    }
  }

  return sessions;
};

export const customSupervisorSort = (
  sessions: SupervisorSessionCE[],
  orderToSort: Record<string, string>[],
): SupervisorSession[] => {
  const sortArr: ((a: SupervisorSessionCE, b: SupervisorSessionCE) => any)[] = [];

  for (const order of orderToSort) {
    switch (order.key) {
      case 'KAD': {
        if (order.order === 'asc') {
          sortArr.push((a, b) => +a.date - +b.date);
        }
        if (order.order === 'desc') {
          sortArr.push((a, b) => +b.date - +a.date);
        }
        break;
      }
      case 'product': {
        if (order.order === 'asc') {
          sortArr.push((a, b) => a.product.localeCompare(b.product) || +a.specialArrangement - +b.specialArrangement);
        }
        if (order.order === 'desc') {
          sortArr.push((a, b) => b.product.localeCompare(a.product) || +b.specialArrangement - +a.specialArrangement);
        }
        break;
      }
      case 'sitting': {
        if (order.order === 'asc') {
          sortArr.push((a, b) => SITTING_VALUES[a.sitting] - SITTING_VALUES[b.sitting]);
        }
        if (order.order === 'desc') {
          sortArr.push((a, b) => SITTING_VALUES[b.sitting] - SITTING_VALUES[a.sitting]);
        }
        break;
      }
      case 'supervisors': {
        if (order.order === 'asc') {
          sortArr.push((a, b) => {
            const aToOneString = a.venueUsers
              .sort((a, b) => a.fullName.localeCompare(b.fullName))
              .map((vu) => vu.fullName)
              .join(' ');
            const bToOneString = b.venueUsers
              .sort((a, b) => a.fullName.localeCompare(b.fullName))
              .map((vu) => vu.fullName)
              .join(' ');

            return aToOneString.localeCompare(bToOneString);
          });
        }
        if (order.order === 'desc') {
          sortArr.push((a, b) => {
            const aToOneString = a.venueUsers
              .sort((a, b) => a.fullName.localeCompare(b.fullName))
              .map((vu) => vu.fullName)
              .join(' ');
            const bToOneString = b.venueUsers
              .sort((a, b) => a.fullName.localeCompare(b.fullName))
              .map((vu) => vu.fullName)
              .join(' ');

            return bToOneString.localeCompare(aToOneString);
          });
        }
        break;
      }
      default: {
        return sessions;
      }
    }
  }

  sessions.sort((a, b) => {
    for (let i = 0; i < sortArr.length; i++) {
      const res = sortArr[i](a, b);

      if (res === 0) {
        continue;
      }
      return res;
    }
  });

  return sessions;
};

export const getSortedSessions = (sessions: Session[], order: Order[]): Session[] => {
  if (!sessions || sessions.length === 0) {
    return [];
  }

  const orderToSort = order
    .sort((a, b) => a.index - b.index)
    .filter((o: Order) => o.order !== 'default')
    .map((o: Order) => ({ key: o.key, order: o.order }));

  const sorted = customSort(sessions, orderToSort);

  return sorted || [];
};

export const getSortedSupervisorSessions = (sessions: SupervisorSessionCE[], order: Order[]): SupervisorSession[] => {
  if (!sessions || sessions.length === 0) {
    return [];
  }

  const orderToSort = order
    .sort((a, b) => a.index - b.index)
    .filter((o: Order) => o.order !== 'default')
    .map((o: Order) => ({ key: o.key, order: o.order }));

  const sorted = customSupervisorSort([...sessions], orderToSort);

  return sorted || [];
};
