import { ChangeEvent, FC, Fragment, useCallback, useMemo, useRef } from 'react';
import { childTestID } from '../../util/test-id';
import { reducer, useReducerState } from './reducer';
import { Group, Option, ReadOptions, ReducerState, StateResult } from './types';
import { useDispatcher } from './useDispatcher';
import { useFocusHandler } from './useFocusHandler';
import { useKeyboardHandler } from './useKeyboardHandler';
import { useActiveOptionId, useRenderContents } from './useRenderContents';
import {
  AssistiveHint,
  Clear,
  ContainerView,
  ContainerViewProps,
  EmptyMessage,
  InputContainerView,
  InputLabelView,
  InputView,
  LastChange,
  ListBoxView,
  Status,
} from './views';

export interface FilterComboBoxProps extends ContainerViewProps {
  id: string;
  groups: Group[];
  options: ReadOptions;
  selected: Option[];
  onSelectionChange: (options: Option[]) => void;
}

const useStateFromProps = (state: StateResult, { selected, options }: FilterComboBoxProps) => {
  useMemo(() => {
    state.current = reducer(state.current, ['OPTIONS_CHANGE', options]);
  }, [options, state]);

  useMemo(() => {
    state.current = reducer(state.current, ['SELECTED_CHANGE', selected]);
  }, [selected, state]);
};

type ChEvent = ChangeEvent<HTMLInputElement>;

const useLastChangeMessage = ({ lastChange }: ReducerState) => {
  return useMemo(() => {
    if (!lastChange) return null;
    if (lastChange.type === 'cleared') return <Fragment>Cleared all search paramaters</Fragment>;
    const { type, option } = lastChange;
    if (type === 'added') return <Fragment>Added {option.label} to search paramaters</Fragment>;
    return <Fragment>Removed {option.label} from search paramaters</Fragment>;
  }, [lastChange]);
};

export const FilterComboBox: FC<FilterComboBoxProps> = (props) => {
  const state = useReducerState();
  const dispatch = useDispatcher(state, props);
  useStateFromProps(state, props);
  useFocusHandler(state, dispatch);

  const skipOpen = useRef(false);
  const onClick = useCallback(() => {
    if (!skipOpen.current) dispatch(['SHOW_SUGGEST']);
    skipOpen.current = false;
  }, [dispatch]);

  const onKeyDown = useKeyboardHandler(state, dispatch);
  const onInputChange = useCallback(({ currentTarget }: ChEvent) => dispatch(['INPUT_CHANGE', currentTarget.value]), [
    dispatch,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { groups, options, selected, onSelectionChange, ...rest } = props;
  const { id, testID } = props;
  const { current } = state;
  const { open, focused, input, refs, suggested } = current;
  const selectListId = childTestID(id, 'selected-listbox');
  const suggestListId = childTestID(id, 'suggested-listbox');
  const inputId = childTestID(id, 'input');
  const assistiveId = childTestID(id, 'assist');
  const lastChangeId = childTestID(id, 'last-change');
  const activeOptionId = useActiveOptionId(current, props);

  const canClear = !!current.selected.length || !!input;
  const [selectedGroups, suggestedGroups] = useRenderContents(current, props, dispatch);
  const lastChangeMessage = useLastChangeMessage(current);

  const onClear = useCallback(() => {
    if (!selectedGroups.length || input) {
      dispatch(['INPUT_CHANGE', '']);
    }
    refs.input.current?.focus();
    skipOpen.current = true;
    dispatch(['CLEAR_SELECTED']);
  }, [dispatch, refs.input, input, selectedGroups.length]);

  return (
    <ContainerView
      focus={focused}
      forwardRef={refs.container}
      onClick={onClick}
      after={
        <span>
          <Clear
            aria-label="Clear the select contents"
            size="small"
            disabled={!canClear}
            onClick={onClear}
            testID={childTestID(testID, 'clear-button')}
          />
        </span>
      }
      {...rest}
    >
      <ListBoxView
        aria-label="Selected search parameters"
        id={selectListId}
        testID={childTestID(testID, 'selected-listbox')}
        variant="selected"
      >
        {selectedGroups}
      </ListBoxView>

      <InputContainerView testID={childTestID(testID, 'input-container')}>
        <InputLabelView
          visible={!input && (!selected || !Object.keys(selected).length)}
          htmlFor={inputId}
          focus={focused}
          testID={childTestID(testID, 'input-label')}
        >
          {!focused ? 'Search & Filter' : 'Select any search criteria'}
        </InputLabelView>
        <InputView
          forwardRef={refs.input}
          id={inputId}
          value={input}
          onChange={onInputChange}
          onKeyDown={onKeyDown}
          aria-expanded={open}
          aria-describedby={`${assistiveId} ${lastChangeId}`}
          aria-controls={`${selectListId} ${suggestListId}`}
          aria-activedescendant={activeOptionId}
        />
      </InputContainerView>

      <ListBoxView
        forwardRef={refs.suggestions}
        aria-label="Available search parameters"
        id={suggestListId}
        testID={childTestID(testID, 'selected-listbox')}
        variant="suggested"
        visible={open}
      >
        {suggestedGroups}
        {!suggestedGroups.length && (
          <EmptyMessage>
            <strong>No search results found.</strong> Please try different search terms.
          </EmptyMessage>
        )}
      </ListBoxView>

      <AssistiveHint aria-hidden="true" id={assistiveId}>
        When autocomplete results are available use up, down, left, right arrows to review and enter to select.
      </AssistiveHint>

      <Status>
        {open && !!suggested.length && <Fragment>{suggested.length} options available.</Fragment>}
        {open && !suggested.length && <Fragment>No available options found.</Fragment>}
      </Status>

      <LastChange id={lastChangeId}>{lastChangeMessage || 'none'}</LastChange>
    </ContainerView>
  );
};
