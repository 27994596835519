import { AxiosError, AxiosRequestConfig } from 'axios';
import { createContext, createElement, FC, useContext, useEffect, useMemo } from 'react';
import { Falsy } from 'react-hooks-async';
import { Article, Result, useAuthRequest } from '../../api';
import { useNotification } from '../../notifications';
import { Route, useRoute } from '../../Routes';

const useArticlesRequest = (run: boolean): Result<Article[]> | null => {
  const params = useMemo((): AxiosRequestConfig | Falsy => run && { url: '/articles' }, [run]);
  const request = useAuthRequest<Article[]>(params);
  return (params && request) || null;
};

interface Context {
  articles: Article[];
  loading: boolean;
  error: AxiosError | Error | null;
}

const Context = createContext<Context>({
  articles: [],
  loading: false,
  error: null,
});

export const useArticlesProvider = (): Context => useContext(Context);

export const ArticlesProvider: FC = ({ children }) => {
  const { dispatch: dispatchNotification, messages: notificationMessages } = useNotification();
  const route = useRoute();
  const active = route[0] === Route.REPORTS;

  const articles = useArticlesRequest(active);

  const loading = useMemo(() => !!articles && !(articles.error || articles.result), [articles]);
  const error = useMemo(() => articles && articles.error, [articles]);

  useEffect(() => {
    if (error) {
      dispatchNotification([
        'ADD',
        { title: 'Reports error', body: notificationMessages.FAILED_FETCH_ARTICLES },
        'ERROR',
      ]);
    }
  }, [dispatchNotification, error, notificationMessages]);

  const data = useMemo(() => articles?.result || [], [articles]);

  return createElement(Context.Provider, { value: { articles: data, loading, error } }, children);
};
