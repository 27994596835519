import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import { childTestID } from '../../util/test-id';
import { reducer, useReducerState } from './reducer';
import { Centre, ReadCentres, StateResult } from './types';
import { useDispatcher } from './useDispatcher';
import { useFocusHandler } from './useFocusHandler';
import { useRenderOptions } from './useRenderOptions';
import {
  AssistiveHint,
  Clear,
  ContainerView,
  ContainerViewProps,
  EmptyMessage,
  InputContainerView,
  InputLabelView,
  InputView,
  ListBoxView,
} from './views';

export interface CentreComboBoxProps extends ContainerViewProps {
  id: string;
  selected: Centre | null;
  centres: ReadCentres;
}

const useStateFromProps = (state: StateResult, { centres, selected }: CentreComboBoxProps) => {
  useMemo(() => {
    state.current = reducer(state.current, ['CENTRES_CHANGE', centres]);
  }, [centres, state]);

  useMemo(() => {
    if (selected) {
      state.current = reducer(state.current, ['SELECTED_CHANGE', selected]);
    }
  }, [selected, state]);
};

type ChEvent = ChangeEvent<HTMLInputElement>;

export const CentreComboBox: FC<CentreComboBoxProps> = (props) => {
  const state = useReducerState();
  const dispatch = useDispatcher(state);
  useStateFromProps(state, props);
  useFocusHandler(state, dispatch);

  const onInputChange = useCallback(({ currentTarget }: ChEvent) => dispatch(['INPUT_CHANGE', currentTarget.value]), [
    dispatch,
  ]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { centres, ...rest } = props;
  const { id, testID } = props;
  const { current } = state;
  const { input, focused, refs, list, selected } = current;
  const centresListId = childTestID(id, 'centres-listbox');
  const inputId = childTestID(id, 'input');
  const assistiveId = childTestID(id, 'assist');

  const centresList = useRenderOptions(list, selected, props);

  const onClear = useCallback(() => {
    refs.input.current?.focus();
    dispatch(['CLEAR_INPUT']);
  }, [dispatch, refs]);

  return (
    <ContainerView {...rest} testID={childTestID(testID, 'container')}>
      <InputContainerView
        forwardRef={refs.container}
        tabIndex={-1}
        focus={focused}
        testID={childTestID(testID, 'input-container')}
      >
        <InputLabelView visible={!input} focus={focused} htmlFor={inputId} testID={childTestID(testID, 'input-label')}>
          {!focused ? 'Enter search item' : 'Enter search term'}
        </InputLabelView>
        <InputView
          forwardRef={refs.input}
          id={inputId}
          value={input}
          onChange={onInputChange}
          aria-describedby={assistiveId}
          aria-controls={centresListId}
          testID={childTestID(testID, 'input')}
        />
        <span>
          <Clear
            aria-label="Clear the input"
            size="small"
            disabled={!input}
            onClick={onClear}
            testID={childTestID(testID, 'clear-button')}
            forwardRef={refs.close}
          />
        </span>
      </InputContainerView>

      <ListBoxView aria-label="Centres list" id={centresListId} testID={childTestID(testID, 'centres-list')}>
        {centresList}
        {!centresList.length && (
          <EmptyMessage>
            <strong>No centres found.</strong> Please try different search terms.
          </EmptyMessage>
        )}
      </ListBoxView>

      <AssistiveHint id={assistiveId} aria-hidden="true">
        Enter search term to filter centres.
      </AssistiveHint>
    </ContainerView>
  );
};
