import { FC, useCallback, useEffect, useState } from 'react';
import { useCentres } from '../../centres';
import { DropdownMenuContainer } from '../DropDown';
import {
  mainWrapper,
  businessDropDownLabel,
  businessContinueButton,
  doneIcon,
  menuItem,
} from './BusinessPageLayout.module.scss';
import { childTestID, dataTestID, WithIProps } from '../../util/test-id';
import { BusinessWidget, BusinessWidgetDropDownMenu } from '../../pages/views';
import clsx from 'clsx';
import { HeadingLBold, TextMRegular, BodyBold } from '../../theme/typography.module.scss';
import { Button, AnchorButton } from '../Button';
import { useHistory } from 'react-router-dom';
import { BusinessStreamName } from '../../api';
import { BusinessStream } from '../../centres/businessStreamCheck';
import { Done } from '../Icon';

const getOptionName = (businessStream: BusinessStreamName): string => {
  switch (businessStream?.toLowerCase()) {
    case BusinessStream.ENGLISH.toLowerCase():
      return `Cambridge English`;
    case BusinessStream.INTERNATIONAL.toLowerCase():
      return `Cambridge Assessment International Education`;
    default:
      return '';
  }
};

interface BusinessItem {
  businessStream: BusinessStreamName;
  currentBusiness: BusinessStreamName;
  testID: string | undefined;
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}
const MenuItem: FC<BusinessItem> = ({ businessStream, currentBusiness, testID, onClick }) => {
  return (
    <AnchorButton
      className={menuItem}
      id={childTestID(testID, `business-${businessStream}-option`)}
      size="medium"
      variant="text"
      data-item="menuitem"
      role="option"
      onClick={onClick}
      testID={childTestID(testID, `business-${businessStream}-option`)}
      tabIndex={0}
    >
      {getOptionName(businessStream)}
      {currentBusiness === businessStream && <Done className={doneIcon} />}
    </AnchorButton>
  );
};

export const BusinessPageLayout: FC<WithIProps<'div'>> = ({ className, testID, ...rest }) => {
  const buttonId = `${testID}-trigger`;
  const menuId = `${testID}-menu`;
  const businessWidgetId = `${testID}-business-widget`;
  const subHeader = 'Before you start, please select your awarding body and centre';
  const dropdownLabel = 'Awarding body';
  const buttonText = 'Continue';

  const { selectedCentre, businessStream, setRedirectBusiness, setBusinessStream } = useCentres();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const onClose = useCallback(() => setIsOpen(false), []);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClick = useCallback(() => setIsOpen((value) => !value), []);
  const onClickContinue = useCallback(() => {
    if (selectedCentre) {
      setRedirectBusiness(false);
      history.push('/centre/' + selectedCentre?.id);
    }
  }, [setRedirectBusiness, selectedCentre, history]);

  useEffect(() => {
    if (selectedCentre && selectedCentre.productGroup) {
      setBusinessStream(businessStream || selectedCentre.productGroup[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCentre]);

  const onClickOption = useCallback(
    (business: BusinessStreamName) => {
      setBusinessStream(business);
      onClose();
    },
    [setBusinessStream, onClose],
  );

  return (
    <div className={clsx(className, mainWrapper)} {...dataTestID(testID)} {...rest}>
      <h1 className={clsx(className, HeadingLBold)}>Welcome</h1>
      <span className={clsx(className, TextMRegular)}>{subHeader}</span>
      <span className={clsx(className, BodyBold, businessDropDownLabel)}>{dropdownLabel}</span>
      <DropdownMenuContainer
        testID={childTestID(testID, `business-dropdown`)}
        open={isOpen}
        onClose={onClose}
        onClick={onOpen}
        onOpen={onOpen}
        downOnly
      >
        <BusinessWidget
          tabIndex={0}
          businessStream={businessStream}
          optionName={getOptionName(businessStream)}
          testID={businessWidgetId}
          id={buttonId}
          onClick={onClick}
          active={isOpen}
          aria-controls={menuId}
        />

        <BusinessWidgetDropDownMenu
          visible={isOpen}
          id={menuId}
          aria-labelledby={buttonId}
          items={
            selectedCentre && selectedCentre.productGroup
              ? selectedCentre.productGroup.map((business: BusinessStreamName) => (
                  <MenuItem
                    currentBusiness={businessStream}
                    businessStream={business}
                    onClick={() => onClickOption(business)}
                    testID={`${testID}-menu-item-${business?.toLocaleLowerCase()}`}
                    key={business}
                  />
                ))
              : []
          }
        />
      </DropdownMenuContainer>
      <Button
        onClick={onClickContinue}
        className={businessContinueButton}
        testID={childTestID(testID, `continue-button`)}
      >
        {buttonText}
      </Button>
    </div>
  );
};
