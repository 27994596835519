import { useMemo } from 'react';
import { Route } from '../Routes';
import { AppBar, AppBarActions, AppBarTabs, AppBarTitle } from '../components/AppBar';
import { UserWidgetDropdown } from './containers';
import { AppTitle, SurveyButton, Tabs, AppTitleLink } from './views';
import { useIsAdmin, useIsHelpdesk, useIsSupervisor } from '../boot';
import { useCentres } from '../centres/';
import { MessagesCentre } from '../messageCentre';
import { businessLink } from './styles.module.scss';
import clsx from 'clsx';
import { isBusinessStreamCE } from '../centres/businessStreamCheck';

export const useMainHeader = (testID?: string, activeRoute?: string): JSX.Element => {
  const { selectedCentre, businessStream, redirectToBusiness } = useCentres();
  const isHelpdesk = useIsHelpdesk();
  const isAdmin = useIsAdmin(selectedCentre && selectedCentre.id);
  const isSupervisor = useIsSupervisor(selectedCentre && selectedCentre.id);
  const hasAccess = useMemo(() => isHelpdesk || isAdmin, [isHelpdesk, isAdmin]);

  return useMemo(
    () => (
      <AppBar testID={testID}>
        <AppBarTitle testID={testID}>
          <AppTitleLink businessStream={businessStream} href="/" />
          <AppTitle businessStream={businessStream} />
          {selectedCentre?.productGroup &&
            selectedCentre?.productGroup.length > 1 &&
            activeRoute !== Route.BUSINESS &&
            !isSupervisor && (
              <a className={clsx(businessLink)} href="/business">
                Change business stream
              </a>
            )}
        </AppBarTitle>
        <AppBarTabs>
          {hasAccess && activeRoute && selectedCentre && !redirectToBusiness && isBusinessStreamCE(businessStream) && (
            <Tabs
              id="menubar"
              tabs={[
                {
                  id: Route.CENTRE,
                  name: 'Main view',
                  href: `/centre/${selectedCentre.id}`,
                },
                {
                  id: Route.SUPERVISORS,
                  name: ' Supervisors',
                  href: `/centre/${selectedCentre.id}/supervisors`,
                },
              ]}
              activeTabPanel={activeRoute}
              testID="menubar"
            />
          )}
        </AppBarTabs>
        <AppBarActions testID={testID}>
          <SurveyButton testID={testID} />
          <UserWidgetDropdown testID={testID} />
          <MessagesCentre testID={testID} />
        </AppBarActions>
      </AppBar>
    ),
    [testID, hasAccess, activeRoute, selectedCentre, businessStream, redirectToBusiness, isSupervisor],
  );
};
