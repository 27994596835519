import { BusinessStreamName } from '../api/models';

export enum BusinessStream {
  ENGLISH = 'English',
  INTERNATIONAL = 'International',
}

export function isBusinessStreamCE(businessStreamName: BusinessStreamName): boolean {
  return businessStreamName?.toLowerCase() === BusinessStream.ENGLISH.toLowerCase();
}

export function isBusinessStreamCI(businessStreamName: BusinessStreamName): boolean {
  return businessStreamName?.toLowerCase() === BusinessStream.INTERNATIONAL.toLowerCase();
}
