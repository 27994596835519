import { AxiosRequestConfig } from 'axios';
import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { Falsy } from 'react-hooks-async';
import { Result, Session, SessionsResponse, useAuthRequest } from '../api';
import { useBootState } from '../boot';
import { useCentres } from '../centres';
import { Route, useRoute } from '../Routes';
import { useProvider } from '../util/useProvider';
import { readSessions, SessionsData } from './readSessions';

const useSessionsItemRequest = (id: string | Falsy): Result<SessionsResponse> | null => {
  const params = useMemo((): AxiosRequestConfig | Falsy => id && { url: `/sessions/${id}` }, [id]);
  const request = useAuthRequest<SessionsResponse>(params);
  return (params && request) || null;
};

type SessionsError =
  | 'failed_to_load_sessions'
  | 'no_access_to_sessions'
  | 'customer_not_found'
  | 'unknown_error'
  | null;

interface SessionsContextProps {
  loading: boolean;
  error: SessionsError | null;
  data: SessionsData | null;
}

const SessionsContext = createContext<SessionsContextProps>({ loading: true, error: null, data: null });

export const useSessions = (): SessionsContextProps => useContext(SessionsContext);
export const useSessionItem = (id: string): Session | null => {
  const { data } = useSessions();
  return data && data.items[id];
};

export const SessionsProvider: FC = ({ children }) => {
  const route = useRoute();
  const { loading: centresLoading, custLoading: customersLoading, businessStream, centresList } = useCentres();
  const boot = useBootState();
  const { dispatch } = boot;

  const selected = route[0] === Route.CENTRE && route[1].params.id;
  const sessions = useSessionsItemRequest(!centresLoading && !customersLoading && selected);
  const currCentreIndex = selected && centresList?.findIndex((centre) => centre.id === selected);
  const loading = Boolean(customersLoading || (sessions && !(sessions.error || sessions.result)));
  const error = sessions && sessions.error;

  const [internalError, setInternalError] = useState<SessionsError>(null);

  useEffect(() => {
    let err = null;

    if (error && 'isAxiosError' in error && error.response) err = error;

    if ((!err || !err.response) && !loading && currCentreIndex === -1) {
      setInternalError('no_access_to_sessions');
      return;
    }

    if (!err || !err.response) return;

    switch (err.response.status) {
      case 401:
        dispatch(['RESPONSE_401', err]);
        break;
      case 403:
        if (['no_access_to_sessions', 'wrong_business_stream_on_user_level'].includes(err.response.data.error)) {
          setInternalError('no_access_to_sessions');
        } else {
          dispatch(['RESPONSE_403', err]);
        }
        break;
      case 404:
        setInternalError('customer_not_found');
        break;

      case 500:
        setInternalError('failed_to_load_sessions');
        break;
      default:
        setInternalError('unknown_error');
        break;
    }
  }, [error, dispatch, currCentreIndex, loading]);

  const sessionsResult = sessions?.result || null;
  // invalid hook call
  const sessionsData = useMemo(() => sessionsResult && readSessions(sessionsResult, businessStream), [
    sessionsResult,
    businessStream,
  ]);

  const value = useMemo<SessionsContextProps>(() => ({ loading, error: internalError, data: sessionsData }), [
    sessionsData,
    loading,
    internalError,
  ]);
  return useProvider(SessionsContext, value, children);
};
