import { createContext, createElement, FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { Centre, Role } from '../api';
import { useBootState } from '../boot';
import { useCentres } from '../centres';
import { useConsent } from '../cookies';

interface customWindow extends Window {
  [key: string]: any;
}
declare const window: customWindow;

const GA_DIMENSION_USER_ROLE = 'userrole';
const GA_DIMENSION_ORGANISATION = 'organisation';

type GAConfigParams = Gtag.CustomParams | Gtag.ControlParams | Gtag.EventParams;
type GaEventType = 'Centre visit';

interface GaEventParams {
  event_category?: string;
  event_label?: string;
  event_name?: string;
}

interface GADimensions {
  organisation?: string;
  userrole?: string;
}

interface GaIds {
  GA4MeasurementId: string;
  UAMeasurementId: string;
}

interface GAProviderContextProps {
  gaIds: GaIds | null;
  setGAConfig: (params?: GAConfigParams) => void;
  callGAEvent: (name: GaEventType, params?: GaEventParams) => void;
  setGADimensions: (params?: GADimensions) => void;
}

const GAProviderContext = createContext<GAProviderContextProps>({
  gaIds: null,
  setGAConfig: () => {
    throw new Error('Missing `GAProvider`');
  },
  callGAEvent: () => {
    throw new Error('Missing `GAProvider`');
  },
  setGADimensions: () => {
    throw new Error('Missing `GAProvider`');
  },
});

const useSendCentre = (centre: Centre | null, roles: string[] | null) => {
  if (!centre || !roles) return null;

  gtag('set', 'user_properties', {
    userrole: roles,
    organisation: centre.id,
  });

  gtag('event', 'centre_visit');
};

const getRoles = (centreRoles: Role[], userRoles: Role[]) => {
  const globalRoles = userRoles.filter((r) => r === 'INTERNAL_COMPLIANCE' || r === 'INTERNAL_HELPDESK');
  return [...centreRoles, ...globalRoles].filter((role, index, array) => index === array.indexOf(role)).sort();
};

const CentreChangeTask: FC = () => {
  const { gaIds } = useGAProvider();
  const { selectedCentre } = useCentres();
  const boot = useBootState();
  const user = !boot.loading && boot.user;

  const centre = useMemo(() => (gaIds && selectedCentre) || null, [gaIds, selectedCentre]);
  const roles = useMemo(() => (user && centre && getRoles(centre.roles, user.userRoles || [])) || null, [centre, user]);

  useSendCentre(centre, roles);

  return null;
};

export const useGAProvider = (): GAProviderContextProps => useContext(GAProviderContext);

export const GAProvider: FC = ({ children }) => {
  const GA4MeasurementId = process.env.REACT_APP_GA4_MEASUREMENT_ID || null;
  const UAMeasurementId = process.env.REACT_APP_GA_UA_MEASUREMENT_ID || null;
  const { consent } = useConsent();

  const gaIds = useMemo<GaIds | null>(
    () => (GA4MeasurementId && UAMeasurementId && consent && { GA4MeasurementId, UAMeasurementId }) || null,
    [GA4MeasurementId, UAMeasurementId, consent],
  );

  const setGAConfig = useCallback(
    (params?: GAConfigParams) => {
      gaIds && gtag('config', gaIds.GA4MeasurementId, { ...params });
    },
    [gaIds],
  );

  const callGAEvent = useCallback(
    (name: GaEventType, params?: GaEventParams) => {
      gaIds && gtag('event', name, { ...params });
    },
    [gaIds],
  );

  const setGADimensions = useCallback(
    (params?: GADimensions) => {
      gaIds && gtag('set', 'user_properties', { ...params });
    },
    [gaIds],
  );

  useEffect(() => {
    if (gaIds) {
      window[`ga-disable-${gaIds.GA4MeasurementId}`] = false;
      window[`ga-disable-${gaIds.UAMeasurementId}`] = false;

      gtag('set', {
        custom_map: {
          dimension1: GA_DIMENSION_USER_ROLE,
          dimension2: GA_DIMENSION_ORGANISATION,
        },
      });
      setGAConfig({ cookie_flags: 'SameSite=None;Secure', cookie_domain: location.hostname });
    }
  }, [gaIds, setGAConfig]);

  const centreChangeTask = createElement(CentreChangeTask);

  return createElement(
    GAProviderContext.Provider,
    { value: { gaIds, setGAConfig, setGADimensions, callGAEvent } },
    children,
    centreChangeTask,
  );
};
